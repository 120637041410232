import gql from 'graphql-tag'

import { PaymentSupportTypeFragment } from '@/graphql/fragments/finance'

export const query = gql`
  ${PaymentSupportTypeFragment}
  query fetchPaymentSupportType($id: Int!) {
    records: finance_payment_support_type_by_pk(id: $id) {
      ...PaymentSupportType
    }
  }
`

export default query
