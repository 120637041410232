import { Auto } from '@/entities/public'

export class CarData {
  fuel: string
  transmission: string
  traction: string
  year: string
  brand: string
  color: string
  model: string
  ppuCav: string
  ppuDigit: string
  emissionDate: string

  constructor (data: Partial<CarData>) {
    Object.assign(this, data)
  }

  /**
   * Getter para extraer y mapear la transmisión desde la cadena del modelo.
   *  - Búsqueda en "automaticTransmissions" → ['AUTOMATICA', 'AUTOMATICA', 'AUTOMATICO', 'AUTOMÁTICO', 'AUT', 'AT', 'CVT']
   *    empuja: ['Automática', 'CVT', 'Automatizada']
   *  - Búsqueda en "manualTransmissions" → ['MANUAL', 'MT']
   *    empuja: ['Mecánica', 'Manual']
   * Si no se encuentra nada, retorna "Sin referencia".
   */
  transmissionCalculated (transmission): string {
    if (!this.model || typeof this.model !== 'string') {
      return 'Sin referencia'
    }
    // Obtenemos los posibles valores
    const transmissions = this.getTransmission(this.model)
    if (!transmissions.length && transmission !== 'Mecánica') {
      return 'Sin referencia'
    } else if (!transmissions.length && transmission === 'Mecánica') {
      return 'Mecánica (por defecto)'
    }
    /**
     * Se asume que si en la lista aparece "Automática", "CVT" o "Automatizada"
     * se considera transmisión automática. Si aparece "Mecánica" o "Manual",
     * se considera transmisión mecánica. Tomamos el primer resultado.
     */
    const first = transmissions?.[0]?.toUpperCase()
    if (['AUTOMÁTICA', 'CVT', 'AUTOMATIZADA'].includes(first)) {
      return 'Automática'
    } else if (['MECÁNICA', 'MANUAL'].includes(first)) {
      return 'Mecánica'
    }

    return 'Sin referencia'
  }

  /**
   * Getter para extraer y mapear la tracción desde la cadena del modelo.
   * Se prioriza "4X4" si encuentra '4X4', 'AWD', '4WD';
   * caso contrario, si encuentra '4X2', 'FWD', 'RWD', '2WD', retorna "4X2".
   * Si no encuentra nada, retorna "4x2" por defecto.
   */
  get tractionCalculated (): string {
    if (!this.model || typeof this.model !== 'string') {
      return '4x2'
    }
    const traction = this.getTraction(this.model)
    return traction || '4x2'
  }

  /**
   * Compara los datos de este vehículo con otro objeto Auto.
   * Retorna un array de objetos con el nombre del campo y si coinciden (true|false|null).
   */
  compareWithAuto (auto: Auto): { name: string, value: boolean | null }[] {
    const comparisons: { name: string, value: boolean | null }[] = []

    // Comparar ppuCav con registrationPlate
    comparisons.push({
      name: `Patente: ${this.ppuCav}`,
      value: this.compareStrings(this.ppuCav, auto.registrationPlate),
    })

    // Extraer información del objeto auto
    const autoBrand = auto.version.version.model.brand.name.trim().toUpperCase()
    const autoModel = auto.version.version.model.name.trim().toUpperCase()
    const autoYear = auto.version.year.id.toString()

    // Comparar brand
    comparisons.push({
      name: `Marca: ${this.brand}`,
      value: this.compareStrings(this.brand, autoBrand),
    })

    // Comparar model
    comparisons.push({
      name: `Modelo: ${this.model}`,
      value: this.compareStrings(this.model, autoModel),
    })

    // Comparar año
    comparisons.push({
      name: `Año: ${this.year}`,
      value: this.year?.trim() === autoYear,
    })

    // Combustible
    const autoFuel = auto.generation?.fuel
    if (autoFuel) {
      comparisons.push({
        name: `Combustible: ${this.fuel}`,
        value: this.compareStrings(this.fuel, autoFuel.componentValue.value),
      })
    }

    // Transmisión
    const autoTransmission = auto.generation?.transmission
    if (autoTransmission) {
      const transmissionComparison = this.compareTransmission(autoTransmission.val)
      comparisons.push({
        name: `Transmisión: ${this.transmissionCalculated(autoTransmission.val)}`,
        value: transmissionComparison,
      })
    }

    // Tracción
    const autoTraction = auto.generation?.traction
    if (autoTraction) {
      const tractionComparison = this.compareTraction(autoTraction.val)
      comparisons.push({
        name: `Tracción: ${this.tractionCalculated}`,
        value: tractionComparison,
      })
    }

    return comparisons
  }

  /**
   * Lógica de comparación de transmisión, adaptada para contemplar
   * valores "MANUAL", "AUTOMÁTICA/CVT" y el caso "SIN REFERENCIA".
   */
  private compareTransmission (autoTransmissionVal: string): boolean | null {
    const thisTrans = this.transmissionCalculated(autoTransmissionVal).trim().toUpperCase()

    if (thisTrans === 'MECÁNICA (POR DEFECTO)') {
      return true
    }

    if (!autoTransmissionVal) {
      return null // No hay info en Auto
    }
    const autoTrans = autoTransmissionVal.trim().toUpperCase()

    // Caso: Transmisión en Auto = "MANUAL"
    if (autoTrans === 'MANUAL') {
      if (!thisTrans || thisTrans === 'SIN REFERENCIA') {
        // Si mi dato está vacío o sin ref, lo consideramos compatible (true).
        return true
      } else {
        // Es true solo si NO es automática.
        return !['AUTOMÁTICA', 'AUTOMATICA', 'CVT'].includes(thisTrans)
      }
      // Caso: Transmisión en Auto = "AUTOMÁTICA" o "CVT"
    } else if (['AUTOMÁTICA', 'AUTOMATICA', 'CVT'].includes(autoTrans)) {
      // Si CarData es Automática/CVT → true
      if (['AUTOMÁTICA', 'AUTOMATICA', 'CVT'].includes(thisTrans)) {
        return true
      } else if (!thisTrans || thisTrans === 'SIN REFERENCIA') {
        // Sin referencia → no se puede confirmar
        return null
      } else {
        // No coincide
        return false
      }
    }

    // Si la transmisión es diferente de "MANUAL" o "AUTOMÁTICA/CVT", devolvemos null
    return null
  }

  /**
   * Lógica de comparación de tracción, inspirada en las reglas del ejemplo:
   * - Si Auto dice 4x2 y mi CarData no especifica (o coincide con 4x2), true.
   * - Si Auto dice 4x4 y mi CarData es 4x4, true.
   * - Sin referencia, a veces es true y a veces null.
   * - Distinto → false
   */
  private compareTraction (autoTractionVal: string): boolean | null {
    const thisTrac = this.tractionCalculated.trim().toUpperCase()
    if (!autoTractionVal) {
      return null // No hay info en Auto
    }
    const autoTrac = autoTractionVal.trim().toUpperCase()

    if (autoTrac === '4X2') {
      if (!thisTrac || thisTrac === 'SIN REFERENCIA') {
        return true
      }
      // Retorna true si NO es 4x4
      return thisTrac !== '4X4'
    } else if (autoTrac === '4X4') {
      if (thisTrac === '4X4') {
        return true
      } else if (!thisTrac || thisTrac === 'SIN REFERENCIA') {
        return null
      }
      return false
    }
    // Si la tracción en Auto no es 4x2 ni 4x4, devolvemos null
    return null
  }

  /**
   * Retorna un array de posibles transmisiones encontradas en la cadena.
   * Ejemplo de acuerdo a las imágenes:
   *   - Si encuentra algún token de "automaticTransmissions", pushea ['Automática', 'CVT', 'Automatizada']
   *   - Si encuentra token de "manualTransmissions", pushea ['Mecánica', 'Manual']
   * Retorna [] si no encuentra nada.
   */
  private getTransmission (model: string): string[] {
    const _model = this.removeAccents(model).toUpperCase()
    const modelParts = _model.split(' ')

    const automaticTransmissions: string[] = [
      'AUTOMATICA',
      'AUTOMÁTICA',
      'AUTOMATICO',
      'AUTOMÁTICO',
      'AUT',
      'AT',
      'CVT',
    ]
    const manualTransmissions: string[] = ['MANUAL', 'MT', 'MEC']

    const results: string[] = []

    // Buscar coincidencia en las transmisiones automáticas
    for (const transmission of automaticTransmissions) {
      const found = modelParts.some(part => part === transmission)
      if (found) {
        results.push('Automática'.toUpperCase())
        results.push('CVT'.toUpperCase())
        results.push('Automatizada'.toUpperCase())
        break
      }
    }

    // Si ya encontró algo y lo pusheó, retornamos de inmediato
    if (results.length) return results

    // Buscar coincidencia en las transmisiones manuales
    for (const transmission of manualTransmissions) {
      const found = modelParts.some(part => part === transmission)
      if (found) {
        results.push('Mecánica'.toUpperCase())
        results.push('Manual'.toUpperCase())
        break
      }
    }

    return results
  }

  /**
   * Retorna "4X4" si detecta '4X4', 'AWD', '4WD' en el modelo,
   * "4X2" si detecta '4X2', 'FWD', 'RWD', '2WD' en el modelo, o null si no coincide.
   */
  private getTraction (model: string): string | null {
    const _model = this.removeAccents(model).toUpperCase()

    const automaticTractions = ['4X4', 'AWD', '4WD']
    for (const traction of automaticTractions) {
      if (_model.includes(traction)) return '4X4'
    }

    const manualTractions = ['4X2', 'FWD', 'RWD', '2WD']
    for (const traction of manualTractions) {
      if (_model.includes(traction)) return '4X2'
    }

    return null
  }

  /**
   * Método auxiliar para comparar dos strings, devolviendo true si al menos
   * una palabra coincide (ignora diacríticos y mayúsculas/minúsculas).
   */
  private compareStrings (a: string, b: string): boolean {
    if (!a || !b) return false
    const cleanA = this.removeAccents(a.trim().toUpperCase())
    const cleanB = this.removeAccents(b.trim().toUpperCase())
    const wordsA = cleanA.split(/\s+/)
    const wordsB = cleanB.split(/\s+/)

    if (wordsB[0] === 'HIBRIDO' && wordsA.some(wordA => wordA.includes('DUAL'))) {
      return true
    }

    return wordsB.some(wordB => {
      return wordsA.some(wordA => wordA.includes(wordB))
    })
  }

  /**
   * Elimina diacríticos (acentos) de una cadena.
   */
  private removeAccents (str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }
}
