import gql from 'graphql-tag'

export const query = gql`
  query findLitePurchase($filter: purchase_purchase_order_bool_exp!) {
    records: purchase_purchase_order(where: $filter) {
      id
    }
  }
`

export default query
