import { Transform, Type } from 'class-transformer'
import dayjs, { Dayjs } from 'dayjs'
import { Entity } from '..'

import { SaleOrder } from './SaleOrder'
import { ClosingReason, ProcessStatus } from '../settings'
import { fixPrice } from '@/utils/general'

export class Reserve extends Entity {
  @Type(() => SaleOrder)
  saleOrder: SaleOrder;

  @Type(() => ClosingReason)
  closingReason: ClosingReason

  @Type(() => ProcessStatus)
  status: ProcessStatus

  @Type(() => Dayjs)
  @Transform(({ value }) => dayjs(value), { toClassOnly: true })
  expiration: Dayjs

  idSaleOrder: number;

  idProcessStatus: number;

  idClosingReason: number | null;
  comment: string

  get expired (): string {
    const { expiration } = this
    if (!expiration) return
    return this.formatDate(expiration)
  }

  get buttonSell () {
    const { saleOrder, id, reserveIsActive } = this

    return {
      disabled: !reserveIsActive,
      parent: { id, model: 'Reserve' },
      id: saleOrder?.id,
      constructor: { name: 'SaleOrder' },
    }
  }

  get reserveIsActive () {
    const { status } = this

    return status?.isActive || status?.isToConfirm
  }

  get payments () {
    const { saleOrder } = this

    if (!saleOrder?.deal?.payments?.length) return ''

    const amount = saleOrder.deal.payments.map(payment => payment.amount).reduce((acc, curr) => acc + curr)
    return fixPrice(amount)
  }

  get autoStock () {
    return this.saleOrder?.deal?.auto
  }
}
