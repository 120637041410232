import { Type } from 'class-transformer'
import { EnumEntity } from '..'

import { BankData } from '.'

export class AccountType extends EnumEntity {
  @Type(() => BankData)
  bankData: BankData[];

  get shortName () {
    if (!this.description) return ''
    let short = this.description

    short = short.replace(/cuenta/i, 'Cta.')
    short = short.replace(/corriente/i, 'Cte')
    short = short.replace(/ahorro/i, 'Ahorro')
    short = short.replace(/vista/i, 'Vista')
    short = short.replace(/Tarjeta de/i, 'T. ')
    short = short.replace(/débito/i, 'Débito')
    short = short.replace(/crédito/i, 'Crédito')

    return short
  }
}
