import dayjs from 'dayjs'

export interface MonthlyReport {
  dates: Object
}

export function initState (): MonthlyReport {
  return {
    dates: {
      start_date: dayjs().startOf('month'),
      end_date: dayjs().endOf('month'),
    },
  }
}
