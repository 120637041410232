import gql from 'graphql-tag'
import { DealFragment } from '@/graphql/fragments/crm'

export const query = gql`
  ${DealFragment}
  query findLiteDeal($filter: crm_deal_bool_exp, $order: [crm_deal_order_by]) {
    records: crm_deal(where: $filter, order_by: $order) {
      ...Deal
      saleOrders: sale_orders {
        id
      }
      stock{
        id
      }
    }
  }
`

export default query
