import { Stock } from '@/entities/sales/Stock'

export class StockAdvertiser extends Stock {
  get car () {
    const { auto } = this

    return auto?.car
  }

  get publishPlatformsWithPost () {
    const { publishPlatforms } = this

    return publishPlatforms?.map(platform => {
      return {
        ...platform,
        post: this[platform.name.toLocaleLowerCase()],
        published: Boolean(this[platform.name.toLocaleLowerCase()]),
      }
    })
  }

  get carDetail () {
    const { car } = this

    return car?.details?.[0]
  }

  get mercadolibre () {
    const { car } = this

    const post = car?.posts?.find(post => post.platform.name === 'MercadoLibre')

    return post?.url
  }

  get chileautos () {
    const { car } = this

    const post = car?.posts?.find(post => post.platform.name === 'ChileAutos')
    const identifier = post?.url.split('details/')?.[1]?.split('?')?.[0]

    if (!identifier) return null

    return `https://www.chileautos.cl/details/${identifier}`
  }

  get yapo () {
    const { car } = this

    const post = car?.posts?.find(post => post.platform.name === 'YAPO')

    const url = post?.url
    if (!url?.length) return null

    return url
  }
}
