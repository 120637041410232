import { ActivityFragment, DealFragment, LeadFragment, NoteFragment } from '@/graphql/fragments/crm'
import { FinancingFragment } from '@/graphql/fragments/loans'
import { BaseAutoFragment } from '@/graphql/fragments/public'
import { DealAutoAttributeFragment } from '@/graphql/fragments/public/dealAutoAttribute'
import { AppraisalFragment } from '@/graphql/fragments/purchase'
import { BaseSaleOrderFragment, SaleOrderItemFragment, StockFragment } from '@/graphql/fragments/sales'
import { AutoMotiveRegistrationFragment } from '@/graphql/fragments/vehicle'
import gql from 'graphql-tag'
import PersonFragment from '../../persons/person/fragment'

export const Fragment = gql`
  ${LeadFragment}
  ${PersonFragment}
  ${DealFragment}
  ${AppraisalFragment}
  ${BaseSaleOrderFragment}
  ${FinancingFragment}
  ${StockFragment}
  ${NoteFragment}
  ${SaleOrderItemFragment}
  ${DealAutoAttributeFragment}
  ${ActivityFragment}
  ${BaseAutoFragment}
  ${AutoMotiveRegistrationFragment}
  ${PersonFragment}
  fragment LeadForm on crm_lead {
    ...Lead
    executive {
      ...Employee
      person{
        ...Person
      }
    }
    client {
      ...PersonForm
    }
    deals {
      ...Deal
      auto {
        ...AutoBase
        ownerships{
          id
          owners{
            id
          }
        }
        version: version_year {
          ...VersionYear
        }
        generation {
          id
          registrations:automotive_registrations{
            ...AutoMotiveRegistration
          }
          sku
        }
        status: process_status {
          ...ProcessStatus
        }
        maintenances (order_by: {type: {mileage: desc}}){
          ...Maintenance
        }
        deals {
          id
          appraisalsAggregate: appraisals_aggregate{
            aggregate {
              count
            }
          }
        }
      }
      status {
        ...ProcessStatus
      }
      closingReason: closing_reason {
        ...ClosingReason
      }
      intention {
        ...Intention
      }
      autoAttributes: auto_attributes{
        ...DealAutoAttribute
      }
      appraisals(order_by: {id: asc}) {
        ...Appraisal
      }
      saleOrders: sale_orders {
        ...BaseSaleOrder
        status {
          ...ProcessStatus
        }
        saleOrderItems: sale_order_items(order_by: {item_type: {id: asc}}) {
          ...SaleOrderItem
        }
        financings(order_by: {id: desc}) {
          ...Financing
        }
        reserve{
          id
          closingReason: closing_reason {
            ...ClosingReason
          }
        }
      }
      stock {
        ...Stock
      }
      notes(order_by: {created_at: asc_nulls_last}, limit: 1) {
        ...Note
      }
    }
    activities(order_by:{id: desc}){
      ...Activity
    }
  }
`
export default Fragment
