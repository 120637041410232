import { Transform, Type } from 'class-transformer'
import { Entity } from '@/entities'
import { InspectionAssessment, InspectionComponent, InspectionInspectedComponent, InspectionParameter } from '.'
import dayjs, { Dayjs } from 'dayjs'

export class InspectionView extends Entity {
  @Type(() => InspectionComponent)
  component: InspectionInspectedComponent

  @Type(() => InspectionParameter)
  parameter: InspectionParameter

  @Type(() => InspectionAssessment)
  assessment: InspectionAssessment

  @Type(() => Dayjs)
  @Transform(({ value }) => value && dayjs(value), { toClassOnly: true })
  date?: Dayjs;

  value: string
  idInspectedComponent: number
  idAuto: number
  idAppraisal: number
  idInspection: number
  supervisorMetadata: object
  inspectorMetadata: object

  get isDocumentation () {
    const { component } = this

    return component?.inspectionComponent?.component?.category?.name === 'Documentación'
  }

  get isKey () {
    const { component } = this

    return component?.inspectionComponent?.componentValue?.value === 'Llaves'
  }

  get isIdentifier () {
    const { component } = this

    return component?.inspectionComponent?.component?.category?.name === 'Identificación'
  }

  get order () {
    const { parameter } = this

    return parameter?.order
  }

  get componentResponse () {
    const { value } = this

    return value
  }

  get componentValueResponse () {
    const { assessment } = this

    return assessment?.option?.name
  }

  get idComponent () {
    const { component } = this

    return component?.inspectionComponent?.component?.id
  }
}
