import { State as RootState } from '@/store/state'
import { ActionTree } from 'vuex'
import { Notification } from '@/store/notification/state'
import { Notification as NotificationEntity } from '@/entities/system/Notification'
import axios from 'axios'
import socket from '@/plugins/socket'
import { plainToInstance } from 'class-transformer'
import dayjs from 'dayjs'

const baseUrl = process.env.VUE_APP_BASE_URL

export const actions: ActionTree<Notification, RootState> = {
  // Acciones existentes
  loadNotification: ({ commit }, payload): void => {
    commit('setNotification', payload)
  },
  cleanNotification: ({ commit }): void => {
    commit('clearNotification')
  },

  // Petición POST a /personalized
  async sendNotificationPersonalized ({ commit }, payload): Promise<void> {
    const message = plainToInstance(NotificationEntity, {
      ...payload.message,
      date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    })
    const url = process.env.VUE_APP_PRODUCER_PERSONALIZED
    const { data } = await axios.post(`${baseUrl}${url}`, {
      user_id: payload.userId,
      message: JSON.stringify(message),
    })
    return data
  },
  // Petición POST a /broadcast
  async sendNotificationBroadcast ({ commit }, payload): Promise<void> {
    const message = plainToInstance(NotificationEntity, {
      ...payload.message,
      date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    })

    const url = process.env.VUE_APP_PRODUCER_BROADCAST
    const { data } = await axios.post(`${baseUrl}${url}`, { message: JSON.stringify(message) })

    return data
  },

  // Petición POST a /consumer/acknowledge
  async removeNotification ({ commit }, payload): Promise<any> {
    try {
      const url = process.env.VUE_APP_CONSUMER_SEEN
      return await axios.post(`${baseUrl}${url}`, {
        message_id: payload.messageId,
        user_id: payload.userId,
      })
    } catch (error) {
      console.error(error)
    }
  },

  // Petición POST a /personalized_rol
  async sendNotificationRol ({ commit }, payload): Promise<void> {
    const message = plainToInstance(NotificationEntity, {
      ...payload.message,
      date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    })
    const url = process.env.VUE_APP_PRODUCER_ROL
    const { data } = await axios.post(`${baseUrl}${url}`, { role: payload.rol, message: JSON.stringify(message) })
    return data
  },

  /**
   * Suscribirse a los eventos de socket según userId y roles.
   * - userId: para notificaciones personalizadas (personalized_notification_{userId})
   * - roles: array de roles para role_notification_{role}
   */
  async subscribeToNotifications ({ commit }, { userId, roles }) {
    // Función auxiliar para parsear y manejar las notificaciones
    const handleNotification = data => {
      const { id, message, type } = JSON.parse(data)
      const newMessage = {
        id, type, ...message,
      }

      commit('setSystemNotification', plainToInstance(NotificationEntity, newMessage))
    }

    // Traer notificaciones almacenadas al conectarse/recargar
    const baseUrl = process.env.VUE_APP_BASE_URL
    const url = process.env.VUE_APP_CONSUMER_STORED
    const roleList = roles.join(',')

    try {
      const notificationsResponse = await axios.get(`${baseUrl}${url}/${userId}?roles=${roleList}`, {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
        params: {
          timestamp: dayjs().format('YYYYMMDDhhmmss'),
        },
      })
      const notificationsData = notificationsResponse.data
      const notifications = [
        ...notificationsData.broadcast,
        ...notificationsData.personalized,
        ...notificationsData.role_based,
      ]

      for (const notification of notifications) {
        const parsedNotification = {
          ...notification,
          ...notification.message,
        }
        delete parsedNotification.message
        commit('setSystemNotification', plainToInstance(NotificationEntity, parsedNotification))
      }
    } catch (e) {
      console.error(e)
    }

    // Manejo de errores de conexión
    socket.on('connect_error', error => {
      console.error('Error de conexión:', error)
    })

    // En caso de desconexión, se intenta reconectar
    socket.on('disconnect', () => {
      console.warn('Socket desconectado, intentando reconectar...')
      socket.connect() // En algunos casos, socket.io reconecta automáticamente si se configura "reconnection: true"
    })

    // Suscripción a notificaciones broadcast
    socket.on('broadcast_notification', handleNotification)

    // Suscripción a notificaciones personalizadas
    socket.on(`personalized_notification_${userId}`, handleNotification)

    // Suscripción a notificaciones basadas en roles
    roles.forEach(role => {
      socket.on(`role_notification_${role}`, handleNotification)
      socket.emit('request_stored_notifications', { user_id: userId, role })
    })
  },
}
