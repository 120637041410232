import { Actions } from '../../interfaces'
import { EnablementTaskType } from '@/entities/purchase'
import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { EnablementTaskType },
  actions: { [EnablementTaskType.name]: actions },
}
