import gql from 'graphql-tag'
import { EntityFields } from '..'
import { ClosingReasonFragment, ProcessStatusFragment } from '../settings'
import { EnablementTaskTypeFragment } from './enablementTaskType'
import { EmployeeFragment } from '../hr'
import { PersonFragment } from '../persons'

export const BaseEnablementTaskFragment = gql`
  fragment EnablementTaskBase on purchase_enablement_task{
    ${EntityFields}
  }
`

export const EnablementTaskFragment = gql`
  ${BaseEnablementTaskFragment}
  ${EnablementTaskTypeFragment}
  ${EmployeeFragment}
  ${ClosingReasonFragment}
  ${ProcessStatusFragment}
  ${PersonFragment}
  fragment EnablementTask on purchase_enablement_task{
    ...EnablementTaskBase
    type{
      ...EnablementTaskType
    }
    status {
      ...ProcessStatus
    }
    closingReason: closing_reason {
      ...ClosingReason
    }
    enablement{
      id
    }
    component{
      id
    }
  }
`
