import gql from 'graphql-tag'
import { NegotiationFragment, ResponseFragment } from '@/graphql/fragments/purchase'
import { Fragment as AppraisalFragment } from '../appraisal/fragment'
import { DealFragment } from '@/graphql/fragments/crm'
import { EmployeeFragment } from '@/graphql/fragments/hr'
import { ClosingReasonFragment, ProcessStatusFragment } from '@/graphql/fragments/settings'

export const Fragment = gql`
  ${NegotiationFragment}
  ${AppraisalFragment}
  ${DealFragment}
  ${EmployeeFragment}
  ${ProcessStatusFragment}
  ${ClosingReasonFragment}
  ${ResponseFragment}
  fragment NegotiationForm on purchase_negotiation {
    ...Negotiation
    purchaseOrder: purchase_order {
      id
    }
    inspection {
      id
      metadata
      supervisorMetadata: supervisor_metadata
      appraisal {
        ...AppraisalBase
        appraiser {
          ...Employee
        }
        processStatus: status {
          ...ProcessStatus
        }
        closingReason: closing_reason {
          ...ClosingReason
        }
        responses(order_by: {created_at: desc_nulls_last}) {
          ...Response
        }
        deal{
          ...Deal
          lead{
            id
            client{
              ...Person
            }
            executive {
              id
              person{
                ...Person
              }
            }
            pipeline {
              id
              name
            }
          }
          appraisals{
            ...AppraisalBase
            appraiser {
              ...Employee
            }
            processStatus: status {
              ...ProcessStatus
            }
            closingReason: closing_reason {
              ...ClosingReason
            }
            responses(order_by: {created_at: desc_nulls_last}) {
              ...Response
            }
          }
        }
      }
    }
  }
`
export default Fragment
