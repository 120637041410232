import gql from 'graphql-tag'
import { EntityFields } from '..'

export const BaseEnablementTaskTypeFragment = gql`
  fragment EnablementTaskTypeBase on purchase_enablement_task_type{
    ${EntityFields}
    name
    description
    icon 
    color 
    active 
  }
`

export const EnablementTaskTypeFragment = gql`
  ${BaseEnablementTaskTypeFragment}
  fragment EnablementTaskType on purchase_enablement_task_type{
    ...EnablementTaskTypeBase
  }
`
