<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.95 11C15.95 10.252 15.884 9.526 15.796 8.8H19.514C19.69 9.504 19.8 10.241 19.8 11C19.8 11.407 19.767 11.803 19.712 12.188C20.471 12.298 21.175 12.54 21.824 12.892C21.934 12.276 22 11.649 22 11C22 4.95 17.05 0 11 0C4.917 0 0 4.95 0 11C0 17.05 4.95 22 11 22C11.649 22 12.276 21.934 12.892 21.824C12.386 20.9 12.1 19.833 12.1 18.7C12.1 18.381 12.133 18.073 12.177 17.765C11.825 18.458 11.44 19.129 11 19.756C10.087 18.436 9.35 16.973 8.899 15.4H12.991C13.651 14.256 14.641 13.332 15.84 12.76C15.906 12.177 15.95 11.594 15.95 11ZM11 2.233C11.913 3.553 12.65 5.027 13.101 6.6H8.899C9.35 5.027 10.087 3.553 11 2.233ZM2.486 13.2C2.31 12.496 2.2 11.759 2.2 11C2.2 10.241 2.31 9.504 2.486 8.8H6.204C6.116 9.526 6.05 10.252 6.05 11C6.05 11.748 6.116 12.474 6.204 13.2H2.486ZM3.388 15.4H6.6C6.985 16.775 7.48 18.095 8.14 19.316C6.127 18.623 4.433 17.215 3.388 15.4ZM6.6 6.6H3.388C4.433 4.774 6.127 3.366 8.14 2.684C7.48 3.905 6.985 5.225 6.6 6.6ZM13.574 13.2H8.426C8.316 12.474 8.25 11.748 8.25 11C8.25 10.252 8.316 9.515 8.426 8.8H13.574C13.673 9.515 13.75 10.252 13.75 11C13.75 11.748 13.673 12.474 13.574 13.2ZM13.849 2.684C15.873 3.377 17.556 4.774 18.612 6.6H15.367C15.015 5.225 14.509 3.905 13.849 2.684Z" fill="white" />
    <path d="M18.95 18.95H18.05V16.25H18.95M18.95 20.75H18.05V19.85H18.95M18.5 14C17.9091 14 17.3239 14.1164 16.7779 14.3425C16.232 14.5687 15.7359 14.9002 15.318 15.318C14.4741 16.1619 14 17.3065 14 18.5C14 19.6935 14.4741 20.8381 15.318 21.682C15.7359 22.0998 16.232 22.4313 16.7779 22.6575C17.3239 22.8836 17.9091 23 18.5 23C19.6935 23 20.8381 22.5259 21.682 21.682C22.5259 20.8381 23 19.6935 23 18.5C23 17.9091 22.8836 17.3239 22.6575 16.7779C22.4313 16.232 22.0998 15.7359 21.682 15.318C21.2641 14.9002 20.768 14.5687 20.2221 14.3425C19.6761 14.1164 19.0909 14 18.5 14Z" fill="white" />
  </svg>
</template>

<script>
  export default {}
</script>
