import { Entity } from '..'

export class PaymentSupportType extends Entity {
  public static readonly contract = 'contract'
  public static readonly ticket = 'ticket'
  public static readonly bill = 'bill'
  public static readonly purchaseOrder = 'purchaseOrder'

  name: string;
  description: string;
  active: boolean

  toString () {
    return this.description
  }

  get isContract () {
    const { name } = this
    return name === PaymentSupportType.contract
  }

  get isTicket () {
    const { name } = this
    return name === PaymentSupportType.ticket
  }

  get isBill () {
    const { name } = this
    return name === PaymentSupportType.bill
  }

  get isPurchaseOrder () {
    const { name } = this
    return name === PaymentSupportType.purchaseOrder
  }
}
