import gql from 'graphql-tag'
import { PaymentSupportTypeFragment } from '@/graphql/fragments/finance'

export const query = gql`
  ${PaymentSupportTypeFragment}
  query findPaymentSupportType($filter: finance_payment_support_type_bool_exp, $order: [finance_payment_support_type_order_by!]) {
    records: finance_payment_support_type(where: $filter, order_by: $order) {
      ...PaymentSupportType
    }
  }
`
export default query
