import { Module } from 'vuex'
import { initState, MonthlyReport } from '@/store/monthlyReports/state'
import { State as RootState } from '@/store/state'
import { actions } from '@/store/monthlyReports/actions'

const module: Module<MonthlyReport, RootState> = {
  namespaced: true,
  state: initState,
  actions,
  // mutations,
  // getters,
}

export default module
