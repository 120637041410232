import { Actions } from '../interfaces'

import payment from './payment'
import paymentOrder from './paymentOrder'
import paymentOrderItem from './paymentOrderItem'
import paymentRecipient from './paymentRecipient'
import paymentType from './paymentType'
import expenseOrder from './expenseOrder'
import financialAccount from './financialAccount'
import financialCategory from './financialCategory'
import dailyAccountBalance from './dailyAccountBalance'
import paymentSupportType from './paymentSupportType'

export const Models = {
  ...payment.Model,
  ...paymentOrder.Model,
  ...paymentOrderItem.Model,
  ...paymentRecipient.Model,
  ...paymentType.Model,
  ...expenseOrder.Model,
  ...financialAccount.Model,
  ...financialCategory.Model,
  ...dailyAccountBalance.Model,
  ...paymentSupportType.Model,
}

export const actions: Record<string, Actions> = {
  ...payment.actions,
  ...paymentOrder.actions,
  ...paymentOrderItem.actions,
  ...paymentRecipient.actions,
  ...paymentType.actions,
  ...expenseOrder.actions,
  ...financialAccount.actions,
  ...financialCategory.actions,
  ...dailyAccountBalance.actions,
  ...paymentSupportType.actions,
}

export const schema = { Models, actions }
export default schema
