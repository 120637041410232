import gql from 'graphql-tag'
import { EntityFields } from '..'

import { PersonFragment } from '@/graphql/fragments/persons'
import { ProcessStatusFragment } from '../settings'
import { AutoFragment } from '@/graphql/fragments/public'
import { EmployeeFragment } from '@/graphql/fragments/hr'

export const BaseDocumentFragment = gql`
  fragment BaseDocument on documents_document{
    ${EntityFields}
    metadata
    date
    expirationDate: expiration_date
  }
`

export const DocumentFragment = gql`
  ${BaseDocumentFragment}
  ${PersonFragment}
  ${ProcessStatusFragment}
  ${AutoFragment}
  ${EmployeeFragment}
  fragment Document on documents_document{
    ...BaseDocument
    metadata
    documentType:type{
      id
      name
    }
    interveners{
      id
      uid
      businessName: business_name
      businessDomicile: business_domicile
      field {
        id
        name
      }
      person{
        ...Person
      }
    }
    support {
      id
      name
    }
    status:process_status{
      ...ProcessStatus
    }
    saleOrders:sale_orders{
      id
      deal{
        id
        auto{
          ...Auto
          ownerships{
            id
            owners{
              id
              person{
                ...Person
              }
            }
          }
        }
        lead{
          id
          executive{
            ...Employee
            person{
              ...Person
            }
          }
        }
      }
    }
  }
`
