import { CavInfo } from '@/entities/files/CavInfo'
import { Transform, Type } from 'class-transformer'
import dayjs, { Dayjs } from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

class RegistryPenalties {
  emissionDate: string
  ppu: string
}

class PenaltiesInfo {
  registry: RegistryPenalties
  tickets: Record<string, any>[]
}

export class FileSerialization {
  @Type(() => CavInfo)
  cavInfo: CavInfo;

  @Type(() => PenaltiesInfo)
  penaltiesInfo: PenaltiesInfo;

  get emissionDate () {
    if (this.cavInfo?.carData?.emissionDate) {
      return this.cavInfo.emissionDate
    }

    return dayjs(this.penaltiesInfo?.registry?.emissionDate)?.format('DD/MM/YYYY')
  }
}
