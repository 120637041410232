import { ActionTree } from 'vuex'
import { MonthlyReport } from './state'
import { State as RootState } from '@/store/state'
import { getMonthlyReportSales } from '@/graphql/customQueries/monthlyReports/sales'
import { getMonthlyReportPurchases } from '@/graphql/customQueries/monthlyReports/purchases'
import { getMonthlyReportInspections } from '@/graphql/customQueries/monthlyReports/inspections'

export const actions: ActionTree<MonthlyReport, RootState> = {
  getMonthlyReportSales: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getMonthlyReportSales,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },
  getMonthlyReportPurchases: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getMonthlyReportPurchases,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },
  getMonthlyReportInspections: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getMonthlyReportInspections,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },
}
