import gql from 'graphql-tag'
import { ModelFragment } from '@/graphql/fragments/vehicle'

export const mutation = gql`
  ${ModelFragment}
  mutation updateModel($id: Int!, $fields: vehicle_model_set_input!) {
    record: update_vehicle_model_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...Model
      idGenio:id_genio
    }
  }
`

export default mutation
