import { Actions } from '../../interfaces'
import { VersionYear } from '@/entities/vehicle'

import find from './find'
import fetch from './fetch'
import update from './update'
import create from './create'

export const actions: Actions = {
  find,
  fetch,
  update,
  create,
}

export default {
  Model: { VersionYear },
  actions: { [VersionYear.name]: actions },
}
