import { Entity, Icon } from '@/models/interfaces'
import { Employee, Person } from '@/models/user/interfaces'
import { Deal } from '@/models/deal/interfaces'

export enum LeadStatus {
  active,
  close,
}

export interface Lead extends Entity {
  status: LeadStatus
  responsible: Employee
  client: Person
  deals: Array<Deal>
}

export interface Alert extends Icon {
  value: string
}

export const LeadStatusIcons: Record<LeadStatus, Icon> = {
  [LeadStatus.active]: {
    name: 'Activo',
    icon: 'mdi-circle',
    color: 'primary',
  },
  [LeadStatus.close]: {
    name: 'Leads cerrado',
    icon: 'mdi-skull',
    background: 'black',
    colorHexadecimal: '#000000',
    tooltip: 'Leads cerrado',
  },
}

// -----------------------------------------------------------------------------
export enum BusinessStatus {
  renewal,
  renewalCredit,
  buy,
  sell,
  sellCredit,
}

export interface Business {
  status: BusinessStatus
  toString: () => string
}

export const BusinessStatusIcons: Record<BusinessStatus, Icon> = {
  [BusinessStatus.renewal]: {
    name: 'RENOVACIÓN',
    icon: 'mdi-circle',
    color: 'green',
  },
  [BusinessStatus.renewalCredit]: {
    name: 'RENOVACIÓN + CRÉDITO',
    icon: 'mdi-diamond',
    color: 'primary',
  },
  [BusinessStatus.buy]: {
    name: 'COMPRA',
    icon: 'mdi-circle',
    color: 'primary',
  },
  [BusinessStatus.sell]: {
    name: 'VENTA',
    icon: 'mdi-circle',
    color: 'green',
  },
  [BusinessStatus.sellCredit]: {
    name: 'VENTA CON CRÉDITO',
    icon: 'mdi-circle',
    color: 'purple',
  },
}

export const enum LeadActivityStatus {
  noManagement,
  delayed,
  toExpired,
  ok,
  changedPrice,
}

export const LeadActivityIcons: Record<LeadActivityStatus, Icon> = {
  [LeadActivityStatus.noManagement]: {
    icon: 'mdi-circle',
    color: 'yellow',
    colorHexadecimal: '#FAD901',
    order: 1,
    tooltip: 'Sin Gestion',
  },
  [LeadActivityStatus.delayed]: {
    icon: 'mdi-circle',
    color: 'red',
    colorHexadecimal: '#F03738',
    order: 2,
    tooltip: 'Atrasada',
  },
  [LeadActivityStatus.changedPrice]: {
    icon: 'mdi-alert-circle',
    color: 'orange',
    colorHexadecimal: '#ff6800',
    order: 2,
    tooltip: 'Cambio de precio stock, actualiza la tarea',
  },
  [LeadActivityStatus.toExpired]: {
    icon: 'mdi-circle',
    color: 'purple',
    colorHexadecimal: '#800080',
    order: 3,
    tooltip: 'Por vencer',
  },
  [LeadActivityStatus.ok]: {
    icon: 'mdi-circle',
    color: 'green',
    colorHexadecimal: '#13CE66',
    order: 4,
    tooltip: 'Ok',
  },
}
