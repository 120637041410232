import { localToUtc } from '@/utils/general'
import { Dayjs } from 'dayjs'

export class Notification {
  id: string
  date: string
  name: string;
  type: string
  description?: string;
  color?: string;
  status?: string
  link?: string
  vehicle?: string

  get dateFormatted (): string {
    const { sentDate } = this
    if (!sentDate) return ''

    return sentDate.format('DD [de] MMMM [de] YYYY [a las] HH:mm')
  }

  get sentDate (): Dayjs {
    const { date } = this
    if (!date) return

    return localToUtc(date)
  }

  get seen (): boolean {
    return Boolean(localStorage
      ?.getItem('seen-notifications')
      ?.includes(this.id))
  }
}
