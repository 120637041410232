import gql from 'graphql-tag'

export const findDocument = gql`
  query findDocument($id: Int!){
    evaluation:loans_evaluation(where: {id: {_eq:$id}}){
      id
      financing{
        id
        sale_order{
          id
          deal{
            id
            stock{
              id
              transfer{
                document_id
              }
            }
          }
        }
      }
    }
  }
`

export const checkMoreThanOneFinancier = gql`
  query checkMoreThanOneFinancier($saleId: Int!, $financierId: Int!){
    check:check_more_than_one_financier(saleId: $saleId, financierId: $financierId)
  }
`
