import { Type } from 'class-transformer'
import { Entity } from '..'

import { Price } from './Price'
import { KeyIdentifier } from './KeyIdentifier'
import { Deal } from '../crm'
import { Enablement } from '../purchase'
import { ProcessStatus } from '../settings'
import { Auto } from '@/entities/public'
import { fixAmountFormatted, fixPrice } from '@/utils/general'
import dayjs from 'dayjs'
import { TransferView } from '@/entities/documents'
import { StockViewDetails } from '@/entities/sales/StockViewDetails'
import { StockDailies } from '@/entities/sales/StockDailies'

export class SocialNetworks {
  [key: string]: {
    url: string
  }
}

export class PublishPlatform {
  id: number
  name: string
  enabled: boolean
}

export class StockMetadata {
  socialNetworks: SocialNetworks
  publishPlatforms?: PublishPlatform[]
}

export class Stock extends Entity {
  @Type(() => Price)
  prices: Price[];

  @Type(() => Deal)
  deals: Deal[];

  @Type(() => KeyIdentifier)
  keyIdentifier: KeyIdentifier;

  @Type(() => Enablement)
  enablement: Enablement;

  @Type(() => ProcessStatus)
  status: ProcessStatus;

  @Type(() => TransferView)
  transfer: TransferView

  @Type(() => StockViewDetails)
  viewDetails: StockViewDetails

  @Type(() => StockDailies)
  dailies: StockDailies[]

  @Type(() => StockMetadata)
  metadata: StockMetadata

  transferCost: number;
  comment: string | null;
  soldDate: string

  get currentPrice () {
    const { prices } = this
    return prices && prices[0]
  }

  get currentPriceFormatted () {
    const { currentPrice } = this
    return currentPrice ? fixPrice(currentPrice?.amount || 0) : ''
  }

  get auto () {
    const { viewDetails, deal } = this

    return viewDetails?.auto || deal?.auto
  }

  get formName () {
    const { auto } = this
    return auto?.formName
  }

  get deal () {
    const { enablement } = this

    return enablement?.inspection?.appraisal?.deal
  }

  get owners () {
    const { viewDetails } = this

    if (!viewDetails?.attributes?.length) return ''

    return viewDetails?.attributes?.filter(attribute => attribute.component.slug === 'owners_number')?.[0]?.val
  }

  get kms () {
    const { viewDetails } = this
    if (!viewDetails?.attributes?.length) return ''
    const kms = viewDetails?.attributes?.filter(attribute => attribute.component.slug === 'mileage') || []

    return fixAmountFormatted(Number(kms?.[0]?.val)) || ''
  }

  get stockStatus () {
    const { status, price } = this

    return {
      status,
      price: price?.amount,
    } as Auto
  }

  get publishPlatforms () {
    const { metadata } = this

    return metadata?.publishPlatforms
  }

  get price () {
    const { prices } = this
    if (!prices?.length) return
    return prices.find(price => price.isLastPrice)
  }

  get priceAmount () {
    const { price } = this

    return price?.amount
  }

  get pieForty () {
    const { price } = this
    if (!price) return

    return { value: price?.amount, pie: 0.4 }
  }

  get pieTwenty () {
    const { price } = this
    if (!price) return

    return { value: price?.amount, pie: 0.2 }
  }

  get transferAmount () {
    const { auto } = this
    const registration = auto?.generation?.registration

    if (!registration?.length) return ''

    const value = registration?.[registration.length - 1]?.permission

    return fixPrice(Number(value))
  }

  get leads () {
    const { deals } = this

    if (!deals?.length) return '0'

    return deals.length.toString()
  }

  get leadsActive () {
    const { deals } = this

    if (!deals?.length) return '0'
    const count = deals.filter(deal => !deal.isClosed).length
    return (count).toString()
  }

  get discount () {
    const { price } = this

    if (!price?.margin) return '0'

    return fixPrice(price.margin)
  }

  get bono () {
    const { price } = this

    if (!price?.bonus?.amount) return '0'

    return fixPrice(price.bonus.amount)
  }

  get bonoWithoutFormat () {
    const { price } = this

    if (!price?.bonus?.amount) return 0

    return price.highestBonus.amount
  }

  get priceDiscount () {
    const { price } = this
    return [
      { value: price?.amount, name: 'Precio' },
      { value: price?.margin, name: 'Descuento' },
      { value: price?.bonus?.amount, name: 'Bono' },
    ]
  }

  get onlyDiscount () {
    const { price } = this
    return [
      { value: price?.margin, name: 'Descuento' },
      { value: price?.bonus?.amount, name: 'Bono' },
    ]
  }

  get received () {
    const { deals } = this

    const totalCount = deals?.length || 0
    const activeCount = deals?.filter(deal => deal.isDealOpen)?.length || 0
    const lastWeekCount = deals?.filter(
      deal => dayjs().diff(this.formatDateDayjs(deal.createdAt), 'day') <= 7
    )?.length || 0

    const counters = [
      { total: totalCount, title: 'Totales' },
      { total: activeCount, title: 'Activos' },
      { total: lastWeekCount, title: 'Última semana' },
    ]

    return counters.map(({ total, title }) => `${total} ${title}`)
  }

  get stockInformation () {
    const { deals, dailies, status } = this

    if (status?.name === 'sold' || status?.name === 'retired') {
      return null
    }

    const pos = dailies?.findIndex(daily => daily.status?.name === 'enabling')

    const daily = pos !== -1 && dailies?.[pos - 1] ? dailies?.[pos - 1] : dailies?.[dailies?.length - 1] || null
    const stockDays = daily ? dayjs().diff(dayjs(daily?.reportDate), 'day') : 0
    const lastDealCreatedAt = deals?.length > 0 ? deals[deals?.length - 1]?.createdAt : this.createdAt
    const noLeadsDays = dayjs().diff(this.formatDateDayjs(lastDealCreatedAt), 'day')
    const leadsPerDay = deals?.length > 0 ? (deals?.length / (stockDays || 1)).toFixed(2) : 0
    const titles = ['En Stock', 'Sin Leads', 'Leads por día']
    const values = [stockDays, noLeadsDays, leadsPerDay]

    return titles.map((title, index) => `${values[index]} ${title}`)
  }

  get appraisal () {
    const { enablement: { inspection: { appraisal } } } = this
    return appraisal
  }

  get utility () {
    const { price, viewDetails } = this

    if (!price?.amount) return
    const enablementSpenses = viewDetails?.totalCost
    const priceList = price?.amount
    const purchasePrice = viewDetails?.purchaseAmount
    const bonus = price.bonus?.amount

    const priceDifference = priceList - (enablementSpenses || 0) - purchasePrice - (bonus || 0)
    const profitPercentage = Math.round((priceDifference / purchasePrice) * 100)

    return [fixPrice(priceDifference), profitPercentage.toString() === 'Infinity' ? '' : `${profitPercentage} %`]
  }

  get buttonCreateLead () {
    const { auto: { id } } = this

    return {
      parent: { id: 'create', model: 'Lead' },
      constructor: { name: 'Lead' },
      props: { stockId: id },
    }
  }

  get acquirer () {
    const { transfer } = this

    if (!transfer) return []

    const { document } = transfer

    if (!document) return []

    const { interveners } = document

    if (!interveners) return []

    return interveners?.filter(intervener => intervener.field.name === 'buyer')?.map(intervener => intervener.person) || []
  }

  get totalCost () {
    const { viewDetails } = this

    return Number(viewDetails?.totalCost || 0) + Number(viewDetails?.purchaseAmount || 0)
  }

  get registrationCost () {
    const { auto: { generation } } = this

    if (!generation?.registration?.length) return 0

    return generation?.registration?.find(reg => reg.year === Number(dayjs().format('YYYY')))?.appraisal
  }

  get stockPriceAlert (): any {
    const { prices } = this

    if (prices?.length < 2) {
      return
    }

    const saleDate = prices[0].createdAt
    const currentDate = dayjs()

    if (currentDate.diff(saleDate, 'day') > 5) return undefined

    if (prices[0].amount > prices[1].amount) {
      return { background: 'red', icon: 'mdi-currency-usd', color: undefined, tooltip: 'Subida de precio' }
    }

    return { background: 'green', icon: 'mdi-currency-usd', color: undefined, tooltip: 'Baja de precio' }
  }

  get isInvoiceAffect () {
    const { viewDetails } = this

    if (viewDetails?.documentType?.name === 'invoice_affect') {
      return { background: 'red', icon: 'mdi-file-document-arrow-right', color: undefined, tooltip: 'Descuenta IVA' }
    }
  }

  get isConsignment () {
    const { viewDetails } = this
    if (viewDetails?.acquisitionType?.name === 'consignment') {
      return { background: 'orange', icon: 'mdi-cart-arrow-right', color: undefined, tooltip: 'Consignación' }
    }
  }

  get alerts () {
    const { stockPriceAlert, isConsignment, isInvoiceAffect, stockNewAlert, commentAlert } = this

    return [stockPriceAlert, isConsignment, isInvoiceAffect, stockNewAlert, commentAlert].filter(Boolean)
  }

  get commentAlert () {
    const { price } = this

    if (!price || !price.comment) return

    return { background: '#6185DB', icon: 'mdi-chat', color: undefined, tooltip: price.comment }
  }

  get stockNewAlert () {
    const { dailies } = this

    const pos = dailies?.findIndex(daily => daily.status?.name === 'enabling')

    if (pos === -1) return

    const daily = dailies?.[pos - 1]

    if (!daily) return
    const date = dayjs(daily?.reportDate)
    const today = dayjs()

    if (today.diff(date, 'day') > 2) return

    return { background: 'yellow', icon: 'mdi-star', color: undefined, tooltip: 'Recién llegado' }
  }

  get enablingDays () {
    const { dailies } = this
    if (!dailies?.length) return 0

    const daily = dailies?.find(daily => daily.status?.name === 'available')

    if (daily) {
      return dayjs().diff(dayjs(dailies?.[dailies?.length - 1]?.reportDate), 'days')
    }

    const enablingDaily = dailies?.[dailies?.length - 1]

    return enablingDaily ? dayjs().diff(dayjs(enablingDaily?.reportDate), 'days') : 0
  }

  get stockDays () {
    const { dailies, soldDate } = this
    if (!dailies?.length) return 0

    const pos = dailies?.findIndex(daily => daily.status?.name === 'enabling')
    let daily
    if (pos === -1) {
      daily = dailies?.[dailies?.length - 1]
    } else {
      daily = pos !== -1 && dailies?.[pos - 1] ? dailies?.[pos - 1] : dailies?.[dailies?.length - 1] || null
    }

    if (daily?.status.name !== 'available') {
      return null
    }

    const compareDate = soldDate ? dayjs(soldDate) : dayjs()

    return daily ? compareDate.diff(dayjs(daily?.reportDate), 'days') : 0
  }

  get days () {
    const { createdAt } = this

    return dayjs().diff(this.formatDateDayjs(createdAt), 'days')
  }
}
