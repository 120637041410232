import gql from 'graphql-tag'

export const query = gql`
  query findInspectionView($filter: purchase_inspection_detail_view_bool_exp){
    records: purchase_inspection_detail_view(where: $filter) {
      id
      idInspectedComponent:id_inspected_component
      idInspection: id_inspection
      idAppraisal: id_appraisal
      inspectorMetadata: inspector_metadata
      supervisorMetadata: supervisor_metadata
    }
  }
`

export default query
