import { Actions } from '../../interfaces'
import { Appraisal } from '@/entities/purchase'

import create from './create'
import fetch from './fetch'
import update from './update'
import find from './find'
import findFull from './findFull'
import findLite from './findLite'

export const actions: Actions = {
  create,
  fetch,
  update,
  find,
  findFull,
  findLite,
}

export default {
  Model: { Appraisal },
  actions: { [Appraisal.name]: actions },
}
