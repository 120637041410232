import { Type } from 'class-transformer'
import { Entity } from '..'

import { AccountType, Person, PersonAccount } from '.'

class BankDataCredit {
  description: string
  value: boolean
}

class BankDataMetadata {
  @Type(() => BankDataCredit)
  credit: BankDataCredit

  creditAmount: string

  get creditAmountAvailable () {
    const { creditAmount } = this

    return Number(creditAmount)
  }

  get isCredit () {
    const { credit } = this

    return credit?.value
  }
}

export class BankData extends Entity {
  @Type(() => Person)
  bank: Person;

  @Type(() => Person)
  holder: Person;

  @Type(() => AccountType)
  accountType: AccountType;

  @Type(() => PersonAccount)
  holders?: PersonAccount[];

  @Type(() => BankDataMetadata)
  metadata: BankDataMetadata;

  @Type(() => Person)
  provider: Person;

  accountNumber: string;

  get name () {
    const { bank, accountNumber, accountType, provider } = this

    const title = provider?.alias ? `/${provider?.alias}` : ''

    return `${accountNumber}/${bank?.alias}/${accountType?.shortName}${title}`
  }

  get shortName () {
    const { bank, accountNumber } = this

    return `${accountNumber}/${bank?.alias}`
  }

  get alias () {
    const { bank } = this

    return `${bank?.alias}`
  }

  get accountTypeDescription () {
    const { accountNumber, accountType } = this

    return `${accountNumber}/${accountType?.description}`
  }

  get isCreditLine () {
    const { metadata } = this

    return metadata?.isCredit
  }
}
