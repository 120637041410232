import gql from 'graphql-tag'
import { ModelFragment } from '@/graphql/fragments/vehicle'

export const mutation = gql`
  ${ModelFragment}
  mutation insertModel($fields: vehicle_model_insert_input!) {
    record: insert_vehicle_model_one(object: $fields) {
      ...Model
      idGenio:id_genio
    }
  }
`

export default mutation
