import gql from 'graphql-tag'

export const query = gql`
  query findInspectionView($filter: purchase_inspection_detail_view_bool_exp){
    records: purchase_inspection_detail_view(where: $filter) {
      id
      idInspection: id_inspection
      idAppraisal: id_appraisal
      date
      inspectorQualification: inspector_qualification
      supervisorQualification: supervisor_qualification
      component {
        id
        cost
        supervisorCost: supervisor_cost
        inspectionComponent: inspection_component {
          id
          component {
            id
            name
          }
        }
      }
      auto {
        id
        registrationPlate: registration_plate
        version: version_year {
          id
          year {
            id
          }
          version {
            id
            name
            model {
              id
              name
              brand {
                id
                name
              }
            }
          }
        }
      }
      inspectorMetadata: inspector_metadata
      supervisorMetadata: supervisor_metadata
      inspector {
        id
        person {
          id
          firstName: first_name
          surname
          phone: secondary_phone
          email
        }
      }
      executive {
        id
        person {
          id
          firstName: first_name
          surname
          phone: secondary_phone
          email
        }
      }
      supervisor {
        id
        person {
          id
          firstName: first_name
          surname
          phone: secondary_phone
          email
        }
      }
      idInspectedComponent:id_inspected_component
      assessment {
        id
        score
        option {
          id
          name
        }
      }
      parameter {
        id
        name
        order
      }
      qualificationValue: qualification_value
      inspectionComponent: inspection_component {
        id
        order
        component {
          id
          name
          category {
            id
            name
          }
        }
        inspectionParameters: inspection_parameters {
          id
          order
          name
        }
        componentValue: component_value {
          id
          value
        }
      }
    }
  }
`

export default query
