import gql from 'graphql-tag'
import { MaintenanceFragment } from '@/graphql/fragments/public/maintenance'

export const query = gql`
  ${MaintenanceFragment}
  query findMaintenance($filter: maintenance_bool_exp!, $order: [maintenance_order_by]) {
    records: maintenance(where:$filter, order_by: $order) {
      ...Maintenance
    }
  }
`
export default query
