import { Type } from 'class-transformer'
import { CarData } from '.'
import { CavCurrOwners } from '@/entities/files/CavCurrOwners'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/es'

dayjs.extend(customParseFormat)
dayjs.locale('es')

export class CavInfo {
  @Type(() => CarData)
  carData: CarData

  @Type(() => CavCurrOwners)
  currOwners: CavCurrOwners[]

  get emissionDate () {
    const dateStr = this.carData.emissionDate?.trim()
    if (!dateStr) return undefined

    // Suponemos formato "D MMMM YYYY"
    const parts = dateStr.split(' ')
    if (parts.length !== 3) return undefined

    const day = Number(parts[0])
    const monthName = parts[1]
    const year = Number(parts[2])

    const monthMapping: Record<string, number> = {
      Enero: 1,
      Febrero: 2,
      Marzo: 3,
      Abril: 4,
      Mayo: 5,
      Junio: 6,
      Julio: 7,
      Agosto: 8,
      Septiembre: 9,
      Octubre: 10,
      Noviembre: 11,
      Diciembre: 12,
    }

    const month = monthMapping[monthName]
    if (!month) return undefined

    // Crea la fecha (dayjs trabaja con meses 0-indexado)
    return dayjs(new Date(year, month - 1, day)).format('DD/MM/YYYY')
  }
}
