import gql from 'graphql-tag'
import { VersionYearFragment } from '@/graphql/fragments/vehicle'

export const mutation = gql`
  ${VersionYearFragment}
  mutation insertVersionYear($fields: vehicle_version_year_insert_input!) {
    record: insert_vehicle_version_year_one(object: $fields) {
      ...VersionYear
      idGenio:id_genio
    }
  }
`

export default mutation
