import { Actions } from '../../interfaces'
import { InspectionView } from '@/entities/purchase'

import find from './find'
import findLite from './findLite'

export const actions: Actions = {
  find,
  findLite,
}

export default {
  Model: { InspectionView },
  actions: { [InspectionView.name]: actions },
}
