import { Type } from 'class-transformer'
import { Entity } from '..'

import { Inspection, InspectionView } from '.'
import { Employee } from '../hr'
import { ClosingReason, ProcessStatus } from '../settings'
import { Auto } from '@/entities/public'
import { Stock } from '@/entities/sales'

class EnablementCategory {
  cost: number | null;
  value: boolean;
  comment: string | null;
}

class EnablementAutoCategory {
  @Type(() => EnablementCategory)
  bodyWork: EnablementCategory;

  @Type(() => EnablementCategory)
  clean: EnablementCategory;

  @Type(() => EnablementCategory)
  documentation: EnablementCategory;

  @Type(() => EnablementCategory)
  inside: EnablementCategory;

  @Type(() => EnablementCategory)
  mechanical: EnablementCategory;

  @Type(() => EnablementCategory)
  wheels: EnablementCategory;
}

const wrongAnswer = [null, undefined]

export class EnablementMetadata {
  @Type(() => EnablementAutoCategory)
  inspector: EnablementAutoCategory;

  @Type(() => EnablementAutoCategory)
  supervisor: EnablementAutoCategory;

  totalCost: number | null;

  get doneIcon () {
    return {
      icon: 'mdi-check-circle',
      color: 'green',
      description: 'Hecho',
    }
  }

  get notDoneIcon () {
    return {
      icon: 'mdi-alert',
      color: 'red',
      description: 'Por actualizar',
    }
  }

  get pendingIcon () {
    return {
      icon: 'mdi-alert',
      color: 'orange',
      description: 'Pendiente',
    }
  }

  get inspectorDone () {
    const { inspector } = this
    const categories = Object.keys(inspector)

    return categories.every(category => inspector[category].value)
  }

  get isDocumentationStatus () {
    const { inspector, doneIcon, notDoneIcon } = this

    return inspector?.documentation?.value ? doneIcon : notDoneIcon
  }

  get isBodyWorkStatus () {
    const { inspector, doneIcon, notDoneIcon } = this

    return inspector?.bodyWork?.value ? doneIcon : notDoneIcon
  }

  get isInsideStatus () {
    const { inspector, doneIcon, notDoneIcon } = this

    return inspector?.inside?.value ? doneIcon : notDoneIcon
  }

  get isWheelsStatus () {
    const { inspector, doneIcon, notDoneIcon } = this

    return inspector?.wheels?.value ? doneIcon : notDoneIcon
  }

  get isMechanicalStatus () {
    const { inspector, doneIcon, notDoneIcon } = this

    return inspector?.mechanical?.value ? doneIcon : notDoneIcon
  }

  get isCleanStatus () {
    const { inspector, doneIcon, notDoneIcon } = this

    return inspector?.clean?.value ? doneIcon : notDoneIcon
  }

  get isDocumentationStatusSupervisor () {
    const { supervisor, doneIcon, notDoneIcon, pendingIcon } = this

    const wrongAnswer = [null, undefined]
    if (wrongAnswer.includes(supervisor?.documentation?.value)) {
      return pendingIcon
    }
    return supervisor?.documentation?.value ? doneIcon : notDoneIcon
  }

  get isBodyWorkStatusSupervisor () {
    const { supervisor, doneIcon, notDoneIcon, pendingIcon } = this
    const wrongAnswer = [null, undefined]
    if (wrongAnswer.includes(supervisor?.bodyWork?.value)) {
      return pendingIcon
    }
    return supervisor?.bodyWork?.value ? doneIcon : notDoneIcon
  }

  get isInsideStatusSupervisor () {
    const { supervisor, doneIcon, notDoneIcon, pendingIcon } = this
    const wrongAnswer = [null, undefined]
    if (wrongAnswer.includes(supervisor?.inside?.value)) {
      return pendingIcon
    }
    return supervisor?.inside?.value ? doneIcon : notDoneIcon
  }

  get isWheelsStatusSupervisor () {
    const { supervisor, doneIcon, notDoneIcon, pendingIcon } = this

    const wrongAnswer = [null, undefined]
    if (wrongAnswer.includes(supervisor?.wheels?.value)) {
      return pendingIcon
    }
    return supervisor?.wheels?.value ? doneIcon : notDoneIcon
  }

  get isMechanicalStatusSupervisor () {
    const { supervisor, doneIcon, notDoneIcon, pendingIcon } = this
    const wrongAnswer = [null, undefined]
    if (wrongAnswer.includes(supervisor?.mechanical?.value)) {
      return pendingIcon
    }
    return supervisor?.mechanical?.value ? doneIcon : notDoneIcon
  }

  get isCleanStatusSupervisor () {
    const { supervisor, doneIcon, notDoneIcon, pendingIcon } = this
    const wrongAnswer = [null, undefined]
    if (wrongAnswer.includes(supervisor?.mechanical?.value)) {
      return pendingIcon
    }
    return supervisor?.clean?.value ? doneIcon : notDoneIcon
  }

  get isSupervisorDone () {
    const { supervisor } = this
    const categories = Object.keys(supervisor)

    return categories.every(category => supervisor[category].value)
  }
}

export class Enablement extends Entity {
  @Type(() => Employee)
  responsible: Employee;

  @Type(() => Inspection)
  inspection: Inspection;

  @Type(() => ProcessStatus)
  status: ProcessStatus;

  @Type(() => ClosingReason)
  closingReason: ClosingReason

  @Type(() => EnablementMetadata)
  metadata: EnablementMetadata;

  @Type(() => InspectionView)
  inspectionView: InspectionView[];

  @Type(() => Employee)
  supervisor: Employee;

  @Type(() => Stock)
  stock: Stock;

  auto: Auto
  idInspection: number

  get inspectionDocumentations () {
    const { inspectionView } = this

    return inspectionView?.filter(inspection => inspection.isDocumentation)
  }

  get inspectionKey () {
    const { inspectionView } = this

    return inspectionView?.filter(inspection => inspection.isKey)
  }

  get inspectionIdentifiers () {
    const { inspectionView } = this

    return inspectionView?.filter(inspection => inspection.isIdentifier)
  }

  get autoStock () {
    const { stock } = this

    return stock.auto
  }
}
