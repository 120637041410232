import gql from 'graphql-tag'
import { EnablementTaskTypeFragment } from '@/graphql/fragments/purchase'

export const query = gql`
  ${EnablementTaskTypeFragment}
  query findEnablementTaskType($filter: purchase_enablement_task_type_bool_exp){
    records: purchase_enablement_task_type(where: $filter) {
    ...EnablementTaskType
    }
  }
`

export default query
