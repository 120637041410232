import { Type } from 'class-transformer'
import { Entity } from '..'

import { AllowedStatus, ClosingReason, Icon, Process, Status } from '.'
import { Deal, Lead, LeadActivity } from '../crm'
import {
  Appraisal,
  AppraisalResponse,
  Enablement,
  Inspection,
  Negotiation,
  NegotiationResponse,
  PurchaseOrder,
} from '../purchase'
import { Auto } from '../public'
import { Evaluation } from '../loans'
import { Reserve, SaleOrder } from '../sales'

export class ProcessStatus extends Entity {
  @Type(() => Status)
  status: Status;

  @Type(() => Process)
  process: Process;

  @Type(() => AllowedStatus)
  nextStatuses: AllowedStatus[];

  @Type(() => ClosingReason)
  closingReasons: ClosingReason[];

  @Type(() => AllowedStatus)
  previousStatuses: AllowedStatus[];

  @Type(() => Auto)
  autos: Auto[];

  @Type(() => Lead)
  leads: Lead[];

  @Type(() => Deal)
  deals: Deal[];

  @Type(() => LeadActivity)
  leadActivities: LeadActivity[];

  @Type(() => Appraisal)
  appraisals: Appraisal[];

  @Type(() => AppraisalResponse)
  appraisalResponses: AppraisalResponse[];

  @Type(() => Inspection)
  inspections: Inspection[];

  @Type(() => Negotiation)
  negotiations: Negotiation[];

  @Type(() => NegotiationResponse)
  negotiationResponses: NegotiationResponse[];

  @Type(() => PurchaseOrder)
  purchaseOrders: PurchaseOrder[];

  @Type(() => Enablement)
  enablements: Enablement[];

  @Type(() => Evaluation)
  evaluations: Evaluation[];

  @Type(() => Reserve)
  reserves: Reserve[];

  @Type(() => SaleOrder)
  saleOrders: SaleOrder[];

  @Type(() => Icon)
  _icon: Icon;

  _description: string;
  order: number | null;
  _color: string;

  get name (): string {
    const { status } = this

    return status?.name
  }

  get higherClosingReason () {
    const { closingReasons } = this
    if (!closingReasons?.length) return null
    const closingHigher = closingReasons.sort((a, b) => b.order - a.order)

    return closingHigher[0]
  }

  get description (): string {
    const { _description, status } = this
    return _description || status?.description
  }

  get title (): string {
    return this.description
  }

  get icon (): Icon {
    const { _icon, status } = this

    if (!_icon && !status?.icon) return undefined

    return _icon || status.icon
  }

  get color (): string {
    const { _color, status } = this

    if (!_color && !status?.color) return undefined

    return _color || status.color
  }

  get isPending (): boolean {
    const { name } = this
    return name === Status.pending
  }

  get isDeprecated (): boolean {
    const { name } = this
    return name === Status.deprecated
  }

  get isClosed (): boolean {
    const { name } = this
    return name === Status.closed
  }

  get isFinished (): boolean {
    const { name } = this
    return name === Status.finished
  }

  get isInSupervision (): boolean {
    const { name } = this
    return name === Status.supervision
  }

  get isNotOffer (): boolean {
    const { name } = this
    return name === Status.noOffer
  }

  get isAppealed (): boolean {
    const { name } = this
    return name === Status.appealed
  }

  get isAppraised (): boolean {
    const { name } = this
    return name === Status.appraised
  }

  get isApproved (): boolean {
    const { name } = this

    return name === Status.approved
  }

  get isReserved (): boolean {
    const { name } = this
    return name === Status.reserved
  }

  get isDone (): boolean {
    const { name } = this
    return name === Status.done
  }

  get isPrepaid (): boolean {
    const { name } = this
    return name === Status.prepaid
  }

  get isSold (): boolean {
    const { name } = this
    return name === Status.sold
  }

  get isSelling (): boolean {
    const { name } = this
    return name === Status.selling
  }

  get isActive (): boolean {
    const { name } = this
    return name === Status.active
  }

  get isToRegister (): boolean {
    const { name } = this
    return name === Status.toRegister
  }

  get isReservationProcess (): boolean {
    const { name } = this
    return name === Status.reservationProcess
  }

  get isRejected (): boolean {
    const { name } = this
    return name === Status.rejected
  }

  get isConditioned (): boolean {
    const { name } = this
    return name === Status.conditioned
  }

  get isLegalReview (): boolean {
    const { name } = this
    return name === Status.legalReview
  }

  get isLegalized (): boolean {
    const { name } = this
    return name === Status.legalize
  }

  get isAppealAnswered (): boolean {
    const { name } = this
    return name === Status.appealAnswered
  }

  get isReadingFile (): boolean {
    const { name } = this
    return name === Status.readingFile
  }

  get isValidCav (): boolean {
    const { name } = this
    return name === Status.validCav
  }

  get isCavNotMatch (): boolean {
    const { name } = this
    return name === Status.cavNotMatch
  }

  get isToUpdating (): boolean {
    const { name } = this
    return name === Status.toUpdate
  }

  get isNotMatch (): boolean {
    const { name } = this
    return name === Status.notMatch
  }

  get isToConfirm (): boolean {
    const { name } = this
    return name === Status.toConfirm
  }

  get isUnpaid (): boolean {
    const { name } = this
    return name === Status.unpaid
  }

  get isPaid (): boolean {
    const { name } = this
    return name === Status.paid
  }

  get isPendingPayment (): boolean {
    const { name } = this

    return name === Status.isPendingPayment
  }

  get isToContract (): boolean {
    const { name } = this
    return name === Status.toContract
  }

  get isToApproved (): boolean {
    const { name } = this
    return name === Status.toApproved
  }

  get isLost (): boolean {
    const { name } = this
    return name === Status.isLost
  }

  get isEnabling (): boolean {
    const { name } = this
    return name === Status.isEnabling
  }

  get isNew (): boolean {
    const { name } = this
    return name === Status.isNew
  }

  get isRetired (): boolean {
    const { name } = this
    return name === Status.isRetired
  }

  get isBuying (): boolean {
    const { name } = this
    return name === Status.isBuying
  }
}
