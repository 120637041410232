import { Transform, Type } from 'class-transformer'
import { Entity } from '..'

import { FinancialAccount, PaymentRecipient, PaymentType } from '.'
import { ClosingReason, Process, ProcessStatus } from '../settings'
import dayjs, { Dayjs } from 'dayjs'
import { Person, PersonAccount } from '../persons'
import { Deal } from '@/entities/crm'
import { Employee } from '@/entities/hr'
import { fixPrice } from '@/utils/general'

export class Payment extends Entity {
  // ------- Incoming ----------
  @Type(() => Person)
  payer: Person

  @Type(() => Person)
  issuingBank: Person

  // ---------Expense ------------
  @Type(() => PersonAccount)
  recipientAccount: PersonAccount

  @Type(() => PaymentRecipient)
  paymentRecipient: PaymentRecipient

  @Type(() => FinancialAccount)
  financialAccount: FinancialAccount

  @Type(() => PaymentType)
  type: PaymentType

  @Type(() => Process)
  process: Process;

  @Type(() => ProcessStatus)
  status: ProcessStatus

  @Type(() => ClosingReason)
  closingReason: ClosingReason

  @Type(() => Deal)
  deal: Deal

  @Type(() => Employee)
  validator: Employee

  @Type(() => Employee)
  responsible: Employee

  @Type(() => Dayjs)
  @Transform(({ value }) => value && dayjs(value), { toClassOnly: true })
  date: Dayjs;

  @Type(() => Dayjs)
  @Transform(({ value }) => value && dayjs(value), { toClassOnly: true })
  expiration: Dayjs;

  @Type(() => PersonAccount)
  accounts: PersonAccount[]

  otherInfo: Record<string, any>[]
  paymentCount: number
  amount: number
  comment: string
  reference: string
  idPaymentRecipient: number
  paymentBackup: Record<string, any>[]
  idLead: number
  idProcessRecord: number
  idProcess: number

  get name () {
    const { type, amount } = this
    return `${type?.description} ${amount}`
  }

  get dateFormatted () {
    const { date } = this
    if (!date) return ''
    return date?.format('DD/MM/YYYY')
  }

  get amountFixed () {
    return fixPrice(this.amount)
  }

  get expenseType () {
    const { paymentRecipient } = this

    return paymentRecipient?.paymentOrderItem?.processExpense?.expense?.description
  }

  get accountInfo () {
    const { recipientAccount } = this

    if (!recipientAccount) return ''

    return recipientAccount?.name
  }

  get gloss () {
    const { deal: { auto } } = this
    return `Pago de ${auto?.name}`
  }

  get expirationLocalDate () {
    const { expiration } = this

    return expiration?.format('DD/MM/YYYY')
  }

  get autoStock () {
    const { deal } = this
    return deal?.auto
  }
}
