import gql from 'graphql-tag'
import { EntityFields } from '..'
import { EmployeeFragment } from '../hr'
import { ProcessStatusFragment } from '../settings'

export const BaseEnablementFragment = gql`
  fragment EnablementBase on purchase_enablement{
    ${EntityFields}
    metadata
  }
`

export const EnablementFragment = gql`
  ${BaseEnablementFragment}
  ${EmployeeFragment}
  ${ProcessStatusFragment}
  fragment Enablement on purchase_enablement{
    ...EnablementBase
    responsible {
      ...Employee
    }
    status {
      ...ProcessStatus
    }
    inspection {
      id
      metadata
      supervisorMetadata: supervisor_metadata
    }
  }
`
