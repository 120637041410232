import { Actions } from '../../interfaces'

import find from './find'
import { ExpenseType } from '@/entities/settings'

export const actions: Actions = {
  find,
}

export default {
  Model: { ExpenseType },
  actions: { [ExpenseType.name]: actions },
}
