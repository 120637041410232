import gql from 'graphql-tag'

export const getBanks = gql`
  query getBanks {
    banks: persons_person(where: { id_company_type: { _eq: 1 } }) {
      id
      alias
    }
  }
`

export const getAccountTypes = gql`
  query getAccountTypes {
    accountTypes: persons_account_type(where: { name: { _nin: ["credit_card","debit_card"] } }) {
      name
      description
    }
  }
`

export const bankAccountData = gql`
  query bankAccountData($account: String!) {
    bankData: persons_bank_data(where: { account_number: { _eq: $account } }) {
      id
      idHolder: id_holder
      idBank: id_bank
      accountNumber: account_number
      accountType: account_type {
        name
      }
      persons: holders{
        id: id_person
        active
        person: holder {
          companyName: company_name
          surname: surname
          firstName: first_name
          rut: uid
        }
      }
    }
  }
`
