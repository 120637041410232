import { RouteConfig } from 'vue-router'
import { isValidNumber } from '@/utils/general'

const extractProps = ({ params: { model, uid, parentModel, parentId, id }, query: { form, returnTo, nested, dryRun } }) => ({
  model: model || '',
  parentModel,
  parentId: Number(parentId),
  uid: isValidNumber(uid) ? Number(uid) : NaN,
  id: isValidNumber(id) ? Number(id) : NaN,
  slug: form || '',
  nested: typeof nested === 'string' ? [nested] : nested,
  returnTo,
  dryRun: Boolean(dryRun),
})

const routes: Array<RouteConfig> = [
  {
    path: '/form',
    component: () => import('@/layouts/form/Index.vue'),
    props: extractProps,
    meta: { requiresLogin: true, public: false },
    children: [
      {
        path: 'playground/:uid?',
        name: 'forms-playground',
        component: () => import('./custom/Playground.vue'),
        meta: { inherit: true },
        props: ({ params: { uid }, query: { model, slug } }) => ({ uid, model, slug }),
      },
      {
        path: 'inspector_inspection/:uid?',
        name: 'generic-inspection-inspector',
        component: () => import('@/views/forms/custom/InspectionView.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'auditor_inspection/:uid?',
        name: 'generic-inspection-auditor',
        component: () => import('@/views/forms/custom/SupervisorInspectionForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'supervisor_inspection/:uid?',
        name: 'generic-inspection-supervisor',
        component: () => import('@/views/forms/custom/SupervisorInspectionForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: ':parentModel?/:parentId?/staff_leadActivity/:uid?',
        name: 'generic-leadActivity',
        component: () => import('@/views/forms/custom/LeadActivityForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'person/:uid?',
        name: 'generic-person',
        component: () => import('@/views/forms/custom/PersonForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'sysop_person_system/:uid?',
        name: 'generic-person-system',
        component: () => import('@/views/forms/custom/SystemForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'staff_lead/:uid?',
        name: 'generic-lead',
        component: () => import('@/views/forms/custom/LeadForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'auditor_lead/:uid?',
        name: 'generic-lead-auditor',
        component: () => import('@/views/forms/custom/LeadForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'financer_evaluation/:uid?',
        name: 'generic-evaluation',
        component: () => import('@/views/forms/custom/EvaluationForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'financer_evaluation_closing/:uid?',
        name: 'generic-close-evaluation',
        component: () => import('@/views/forms/custom/CloseEvaluationForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'staff_negotiation/:uid?',
        name: 'generic-negotiation',
        component: () => import('@/views/forms/custom/NegotiationExecutiveForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'supervisor_negotiation/:uid?',
        name: 'generic-negotiation-supervisor',
        component: () => import('@/views/forms/custom/SupervisorNegotiationForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'auditor_negotiation/:uid?',
        name: 'generic-negotiation-auditor',
        component: () => import('@/views/forms/custom/SupervisorNegotiationForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: ':model/:uid?/financer_quota/:id?',
        name: 'generic-quota',
        component: () => import('@/views/forms/custom/QuotaForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'forwarder_lead/:uid?',
        name: 'generic-lead-forwarder',
        component: () => import('@/views/forms/custom/ForwarderLeadForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'supervisor_lead/:uid?',
        name: 'generic-lead-supervisor',
        component: () => import('@/views/forms/custom/ForwarderLeadForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: ':model/:uid?/staff_financing/:id?',
        name: 'generic-financial',
        component: () => import('@/views/forms/custom/FinancialForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'staff_appraisal/:uid?',
        name: 'generic-appraisal-edit',
        component: () => import('@/views/forms/custom/AppraisalForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'forwarder_appraisal/:uid?',
        name: 'generic-forwarder-appraisal-edit',
        component: () => import('@/views/forms/custom/AppraisalForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'auditor_appraisal/:uid?',
        name: 'generic-appraisal-auditor',
        component: () => import('@/views/forms/custom/AppraisalForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: ':model/:uid?/staff_appraisal/:id?',
        name: 'generic-appraisal',
        component: () => import('@/views/forms/custom/AppraisalForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: ':model/:uid?/forwarder_appraisal/:id?',
        name: 'generic-forwarder-appraisal',
        component: () => import('@/views/forms/custom/AppraisalForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: ':parentModel?/:parentId?/staff_purchase_order/:id?',
        name: 'generic-purchase',
        component: () => import('@/views/forms/custom/PurchaseForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'staff_consignment/:uid?',
        name: 'generic-consignment',
        component: () => import('@/views/forms/custom/ConsignmentForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'supervisor_consignment/:uid?',
        name: 'generic-consignment-supervisor',
        component: () => import('@/views/forms/custom/ConsignmentForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'staff_purchase_order/:uid?',
        name: 'generic-purchase-edit',
        component: () => import('@/views/forms/custom/PurchaseForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'supervisor_purchase_order/:uid?',
        name: 'generic-purchase-supervisor',
        component: () => import('@/views/forms/custom/PurchaseForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'staff_purchase_stock/:uid?',
        name: 'generic-new-purchase',
        component: () => import('@/views/forms/custom/PurchaseStockForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'appraiser_appraisal/:uid?',
        name: 'generic-appraisal-appraiser-edit',
        component: () => import('@/views/forms/custom/AppraiserAppraisalForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: ':parentModel?/:parentId?/staff_reserve/:uid?',
        name: 'generic-reserve-edit',
        component: () => import('@/views/forms/custom/ReserveForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: ':parentModel?/:parentId?/supervisor_reserve/:uid?',
        name: 'generic-reserve-supervisor',
        component: () => import('@/views/forms/custom/ReserveForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: ':parentModel?/:parentId?/staff_payment/:uid?',
        name: 'generic-payment',
        component: () => import('@/views/forms/custom/PaymentDetailForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: ':parentModel?/:parentId?/supervisor_payment/:uid?',
        name: 'generic-payment-supervisor',
        component: () => import('@/components/forms/validationSaleOrder/validationPaymentComponent.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: ':parentModel?/:parentId?/staff_expenses/:uid?',
        name: 'generic-expenses',
        component: () => import('@/views/forms/custom/ExpensesForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: ':parentModel?/:parentId?/staff_expense_order/:uid?',
        name: 'generic-expense-order',
        component: () => import('@/views/forms/custom/ExpensesOrderForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: ':parentModel?/:parentId?/staff_reserve/:uid?',
        name: 'generic-reserve',
        component: () => import('@/views/forms/custom/ReserveForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'supervisor_sale_order/:uid?',
        name: 'generic-supervisor-sale-order',
        component: () => import('@/views/forms/custom/SaleOrderForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'staff_sale_order/:uid?',
        name: 'generic-sale-order',
        component: () => import('@/views/forms/custom/SaleOrderForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'validator_sale_order/:uid?',
        name: 'generic-validator-sale-order',
        component: () => import('@/views/forms/custom/ValidateSaleOrderForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'validator_reserve/:uid?',
        name: 'generic-validator-reserve',
        component: () => import('@/views/forms/custom/ValidateReserveForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'supervisor_appraisal/:uid?',
        name: 'generic-appraisal-supervisor-edit',
        component: () => import('@/views/forms/custom/SupervisorAppraisalForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'inspector_enablement/:uid?',
        name: 'generic-enablement',
        component: () => import('@/views/forms/custom/EnablementForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'auditor_enablement/:uid?',
        name: 'generic-enablement-auditor',
        component: () => import('@/views/forms/custom/SupervisorEnablementForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'supervisor_enablement/:uid?',
        name: 'generic-supervisor-enablement',
        component: () => import('@/views/forms/custom/SupervisorEnablementForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'inspector_enablement_task/:uid?',
        name: 'generic-enablement-task',
        component: () => import('@/views/forms/custom/EnablementExpenseForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: ':parentModel?/:parentId?/staff_schedule_inspection/:uid?',
        name: 'generic-inspection',
        component: () => import('@/views/forms/custom/InspectionForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'staff_schedule_inspection/:uid?',
        name: 'generic-inspection-edit',
        component: () => import('@/views/forms/custom/InspectionForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: ':model/:uid?/auto/:id?',
        name: 'generic-auto',
        component: () => import('@/views/forms/custom/AutoForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'auto/:uid?',
        name: 'generic-auto-edit',
        component: () => import('@/views/forms/custom/AutoForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: ':model?/:uid?/person/:id?',
        name: 'generic-person-nested',
        component: () => import('@/views/forms/custom/PersonForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'person/:uid?/bankData/:id',
        name: 'person-bank-data',
        component: () => import('@/views/forms/custom/BankDataForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'person/:uid?/personAddress/:id',
        name: 'person-address-data',
        component: () => import('@/views/forms/custom/PersonAddress.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'person/:uid?/personLaborData/:id',
        name: 'person-labor-data',
        component: () => import('@/views/forms/custom/LaborDataForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'person/:uid?/personPhoneReference/:id',
        name: 'person-reference-data',
        component: () => import('@/views/forms/custom/PersonPhoneReferenceForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'validator_transfer/:uid',
        name: 'generic-document-transfer',
        component: () => import('@/views/forms/custom/ValidateTransferForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'validator_purchase_order/:uid',
        name: 'generic-validator-purchase-order',
        component: () => import('@/views/forms/custom/ValidatorPurchaseOrderForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'validator_consignment/:uid',
        name: 'generic-validator-consignment',
        component: () => import('@/views/forms/custom/ValidatorConsignmentForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'supervisor_stock_price/:uid',
        name: 'generic-stock-price',
        component: () => import('@/views/forms/custom/SupervisorStockPriceForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'auditor_stock_price/:uid',
        name: 'generic-stock-auditor',
        component: () => import('@/views/forms/custom/SupervisorStockPriceForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'advertiser_publish_stock/:uid',
        name: 'generic-publish-stock',
        component: () => import('@/views/forms/custom/AdvertiserPublishStockForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'treasurer_expense/:uid?',
        name: 'generic-treasurer-expense',
        component: () => import('@/views/forms/custom/TreasurerExpenseForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'treasurer_income/:uid?',
        name: 'generic-treasurer-income',
        component: () => import('@/views/forms/custom/TreasurerIncomeForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'treasurer_account_balance/:uid?',
        name: 'generic-daily-account-balance',
        component: () => import('@/views/forms/custom/DailyAccountBalanceForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'sysop_employee/:uid?',
        name: 'generic-sysop-employee',
        component: () => import('@/views/forms/custom/SysopEmployeeForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: 'inspector_stock_documentation/:uid?',
        name: 'generic-stock-documentation',
        component: () => import('@/views/forms/custom/StockDocumentationForm.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
      {
        path: ':model/:uid?/:parentModel?/:parentId?/',
        name: 'model_form',
        component: () => import('./GenericView.vue'),
        meta: { inherit: true },
        props: extractProps,
      },
    ],
  },
]

export default routes
