import gql from 'graphql-tag'
import { QueryBuilder } from '../utils'

import { Appraisal } from '@/entities/purchase'
import { AppraisalFragment, BaseInspectionFragment } from '@/graphql/fragments/purchase'
import {
  ActivityTypeFragment,
  BaseActivityFragment,
  ChannelFragment,
  DealFragment,
  DealTypeFragment,
  LeadFragment,
  PipelineFragment,
} from '@/graphql/fragments/crm'
import { BaseStockFragment, PriceFragment } from '@/graphql/fragments/sales'
import { BaseAutoFragment } from '@/graphql/fragments/public'
import { PersonFragment } from '@/graphql/fragments/persons'
import { ProcessFragment } from '@/graphql/fragments/settings'
import { RotationIndexFragment } from '@/graphql/fragments/vehicle'

const query = gql`
  ${AppraisalFragment}
  ${DealFragment}
  ${LeadFragment}
  ${BaseInspectionFragment}
  ${BaseStockFragment}
  ${PriceFragment}
  ${ProcessFragment}
  ${BaseAutoFragment}
  ${PipelineFragment}
  ${PersonFragment}
  ${ActivityTypeFragment}
  ${BaseActivityFragment}
  ${RotationIndexFragment}
  ${DealTypeFragment}
  ${ChannelFragment}
  query appraisalExecutiveLeads($filter: purchase_appraisal_bool_exp, $order: [purchase_appraisal_order_by!], $limit: Int, $offset: Int) {
    records: purchase_appraisal(where: $filter, order_by: $order, limit: $limit, offset: $offset) {
      ...Appraisal
      detail {
        id
      }
      responsesAggregate:responses_aggregate(where: {type: {_eq: appraised}}) {
        aggregate {
          count
        }
      }
      deal {
        channel {
          ...Channel
        }
        id
        autoAttributes:auto_attributes(where:{component:{slug:{_eq: "mileage"}}}, order_by: {id: desc}, limit: 1) {
          id
          value
          component {
            id
            slug
          }
        }
        auto{
          ...AutoBase
          version: version_year {
            ...VersionYear
          }
          generation {
            id
            rotationIndex: rotation_index {
              ...RotationIndex
            }
          }
          status: process_status {
            ...ProcessStatus
          }
          deals{
            id
            type: deal_type{
              name
            }
            appraisalsAggregate: appraisals_aggregate {
              aggregate {
                count
              }
            }
          }
        }
        lead {
          id
          pipeline {
            ...Pipeline
          }
          client {
            ...Person
          }
          deals(where: {type: {_eq: sale}}){
            id
            auto {
              ...Auto
            }
            type: deal_type {
              ...DealType
            }
            stock{
              id
              prices(order_by: {id: desc}, limit: 2) {
                ...Price
              }
            }
          }
          executive{
            id
            person{
              ...Person
            }
          }
          activities (order_by: {created_at: desc_nulls_last}, where: { id_activity_type: { _nin: [6,7,8,9] }} ) {
            ...ActivityBase
            status: process_status {
              ...ProcessStatus
            }
            type: activity_type {
              ...ActivityType
            }
          }
        }
      }
      inspection {
        ...InspectionBase
        status {
          ...ProcessStatus
        }
      }
    }
    total: purchase_appraisal_aggregate(where: $filter) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`

export const appraisals = QueryBuilder(query, Appraisal)
export default appraisals
