import gql from 'graphql-tag'
import { QueryBuilder } from '../utils'
import { Financing } from '@/entities/loans'
import { FinancingFragment } from '@/graphql/fragments/loans'
import { VersionYearFragment } from '@/graphql/fragments/vehicle'
import { ClosingReasonFragment, ProcessStatusFragment } from '@/graphql/fragments/settings'
import { BaseAutoFragment } from '@/graphql/fragments/public'
import { PersonFragment } from '@/graphql/fragments/persons'
import { EmployeeFragment } from '@/graphql/fragments/hr'
import { PriceFragment, SaleOrderItemFragment } from '@/graphql/fragments/sales'
import { ActivityTypeFragment, BaseActivityFragment } from '@/graphql/fragments/crm'

const query = gql`
  ${FinancingFragment}
  ${SaleOrderItemFragment}
  ${PersonFragment}
  ${BaseAutoFragment}
  ${VersionYearFragment}
  ${ProcessStatusFragment}
  ${EmployeeFragment}
  ${BaseActivityFragment}
  ${ActivityTypeFragment}
  ${ClosingReasonFragment}
  ${PriceFragment}
  query financings($filter: loans_financing_bool_exp, $order: [loans_financing_order_by!], $limit: Int, $offset: Int) {
    records: loans_financing(where: $filter, order_by: $order, limit: $limit, offset: $offset) {
      ...Financing
      saleOrder:sale_order {
        id
        saleOrderItems:sale_order_items{
          ...SaleOrderItem
        }
        deal {
          id
          lead {
            id
            executive {
              ...Employee
              person{
                ...Person
              }
            }
            client {
              ...Person
            }
            activities (order_by: {created_at: desc_nulls_last}, where: { id_activity_type: { _nin: [6,7,8,9] }} ) {
              ...ActivityBase
              status: process_status {
                ...ProcessStatus
              }
              type: activity_type {
                ...ActivityType
              }
            }
          }
          auto{
            ...AutoBase
            version: version_year {
              ...VersionYear
            }
            status: process_status {
              ...ProcessStatus
            }
          }
          stock{
            id
            status {
              ...ProcessStatus
            }
            prices(order_by: {id: desc}, limit: 2) {
              ...Price
            }
          }
        }
      }
      evaluations{
        id
        responsibleAgreement:responsible_agreement {
          id
          responsible{
            ...Person
          }
        }
        status{
          order
          closingReasons:closing_reasons {
            ...ClosingReason
          }
        }
      }
    }
    total: loans_financing_aggregate(where: $filter) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`

export const financings = QueryBuilder(query, Financing)
export default financings
