import gql from 'graphql-tag'
import { StockDocumentation } from '@/entities/sales'
import { QueryBuilder } from '../utils'
import { BaseStockFragment, KeyIdentifierFragment, PriceFragment, StockViewDetailBase } from '@/graphql/fragments/sales'
import {
  BaseAttributeFragment,
  BaseComponentFragment,
  BaseComponentValueFragment,
  BaseVersionYearFragment,
  VersionFragment,
  YearFragment,
} from '@/graphql/fragments/vehicle'
import { AcquisitionTypeFragment, ProcessStatusFragment } from '@/graphql/fragments/settings'
import { PipelineFragment } from '@/graphql/fragments/crm'
import { AutoFragment } from '@/graphql/fragments/public'

const query = gql`
  ${BaseStockFragment}
  ${VersionFragment}
  ${BaseVersionYearFragment}
  ${BaseAttributeFragment}
  ${BaseComponentFragment}
  ${BaseComponentValueFragment}
  ${YearFragment}
  ${PriceFragment}
  ${ProcessStatusFragment}
  ${KeyIdentifierFragment}
  ${PipelineFragment}
  ${StockViewDetailBase}
  ${AutoFragment}
  ${AcquisitionTypeFragment}
  query stockDocumentation($filter: sales_stock_bool_exp, $order: [sales_stock_order_by!], $limit: Int, $offset: Int) {
    records: sales_stock(order_by: $order, limit: $limit, offset: $offset, where: $filter) {
      ...StockBase
      viewDetails: stock_detail {
        ...StockViewDetailBase
        acquisitionType: acquisition_type{
          ...AcquisitionType
        }
        auto{
          ...Auto
          generation {
            id
            registration:automotive_registrations{
              id
              permission
            }
          }
        }
        
      }
      status {
        ...ProcessStatus
      }
    }
    total: sales_stock_aggregate(where: $filter) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`

export const stockDocumentation = QueryBuilder(query, StockDocumentation)
export default stockDocumentation
