import gql from 'graphql-tag'
import { VersionFragment } from '@/graphql/fragments/vehicle'

export const mutation = gql`
  ${VersionFragment}
  mutation updateVersion($id: Int!, $fields: vehicle_version_set_input!) {
    record: update_vehicle_version_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...Version
      idGenio:id_genio
      years{
        id
        idGenio:id_genio
        year{
          id
        }
      }
    }
  }
`

export default mutation
