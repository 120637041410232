import { Actions } from '../../interfaces'

import { PaymentSupportType } from '@/entities/finance'

import fetch from './fetch'
import find from './find'

export const actions: Actions = {
  fetch,
  find,
}

export default {
  Model: { PaymentSupportType },
  actions: { [PaymentSupportType.name]: actions },
}
