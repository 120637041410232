import { Type } from 'class-transformer'
import { Entity } from '..'

import { VersionYear } from '.'

export class Year extends Entity {
  @Type(() => VersionYear)
  versions: VersionYear[];

  idGenio: number
}
