import gql from 'graphql-tag'
import { AppraisalFragment } from '@/graphql/fragments/purchase'

export const query = gql`
  ${AppraisalFragment}
  query findAppraisal($filter: purchase_appraisal_bool_exp, $order: [purchase_appraisal_order_by!], $limit: Int) {
    records: purchase_appraisal(where: $filter, order_by: $order, limit: $limit) {
      ...Appraisal
    }
  }
`

export default query
