import gql from 'graphql-tag'

export const query = gql`
  query findAppraisalLite($filter: purchase_appraisal_bool_exp) {
    records: purchase_appraisal(where: $filter) {
      id
    }
  }
`

export default query
