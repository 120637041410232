import gql from 'graphql-tag'
import { EnablementTaskFragment } from '@/graphql/fragments/purchase'

export const mutation = gql`
  ${EnablementTaskFragment}
  mutation updateEnablementTask($id: Int!, $fields: purchase_enablement_task_set_input!) {
    record: update_purchase_enablement_task_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...EnablementTask
    }
  }
`

export default mutation
