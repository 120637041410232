import { Type } from 'class-transformer'
import { Entity } from '..'
import { Auto, DealAutoAttribute } from '@/entities/public'
import { EnablementMetadata, InspectionMetadata } from '@/entities/purchase'
import { AcquisitionType, SupportDocumentType } from '@/entities/settings'
import { Employee } from '@/entities/hr'
import { Stock } from '@/entities/sales/Stock'

export class StockViewDetails extends Entity {
  @Type(() => Auto)
  auto: Auto;

  @Type(() => EnablementMetadata)
  enablementMetadata: EnablementMetadata;

  @Type(() => DealAutoAttribute)
  attributes: DealAutoAttribute[];

  @Type(() => SupportDocumentType)
  documentType: SupportDocumentType;

  @Type(() => AcquisitionType)
  acquisitionType: AcquisitionType;

  @Type(() => InspectionMetadata)
  supervisorMetadata: InspectionMetadata;

  @Type(() => Employee)
  executive: Employee;

  @Type(() => Stock)
  stock: Stock;

  expectedPublicationAmount: number | null;
  appraisalAmount: number | null;
  enablementCost: number | null;
  purchaseAmount: number | null;
  appraisalLink: string | null
  idDeal: number;
  idAuto: number;
  idEnablement: number;
  idNegotiation: number;
  idPurchase: number;
  idInspection: number;
  idAppraisal: number;

  get totalCost () {
    const { enablementMetadata } = this
    return enablementMetadata?.totalCost || 0
  }

  get consignmentExecutive () {
    const { acquisitionType, executive } = this

    if (acquisitionType?.name === 'consignment') {
      return executive
    }
  }
}
