<template>
  <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.54 15.276L24 5.832V19.86V22.26H0V0.66H2.4V15.708L9 4.26L16.8 8.796L21.888 0L23.964 1.2L17.688 12.06L9.876 7.56L2.772 19.86H5.484L10.752 10.788L18.54 15.276Z" fill="#6185DB" />
    <path d="M18.8523 11.7422C19.5526 11.7422 20.2242 12.0204 20.7193 12.5156C21.2145 13.0107 21.4927 13.6823 21.4927 14.3826C21.4927 15.0829 21.2145 15.7545 20.7193 16.2497C20.2242 16.7449 19.5526 17.023 18.8523 17.023C18.152 17.023 17.4804 16.7449 16.9852 16.2497C16.49 15.7545 16.2118 15.0829 16.2118 14.3826C16.2118 13.6823 16.49 13.0107 16.9852 12.5156C17.4804 12.0204 18.152 11.7422 18.8523 11.7422ZM18.8523 18.3433C21.7699 18.3433 24.1331 19.5249 24.1331 20.9837V22.3039H13.5714V20.9837C13.5714 19.5249 15.9346 18.3433 18.8523 18.3433Z" fill="#FAD901" />
    <path d="M17.6438 7L15.5 5.62401L13.3125 7L13.9075 4.40501L12 2.66887L14.5113 2.43799L15.5 0L16.4713 2.43799L19 2.66887L17.075 4.40501L17.6438 7Z" fill="#FAD901" />
  </svg>
</template>

<script>
  export default {}
</script>
