import { Type } from 'class-transformer'
import { Entity } from '..'

import { Negotiation } from '.'
import { AcquisitionType, ClosingReason, ProcessStatus, SupportDocumentType, TransferType } from '../settings'
import { Employee } from '../hr'
import { fixPrice } from '@/utils/general'
import { Payment, PaymentOrder } from '@/entities/finance'
import { Deal } from '@/entities/crm'
import { Auto } from '@/entities/public'
import { FileValidation } from '@/entities/files'
import { StockViewDetails } from '@/entities/sales'

const enum IndicatorColor {
  blue = 'blue',
  green = 'green'
}

export class PurchaseOrder extends Entity {
  @Type(() => TransferType)
  transferType: TransferType;

  @Type(() => SupportDocumentType)
  supportDocumentType: SupportDocumentType;

  @Type(() => Negotiation)
  negotiation: Negotiation;

  @Type(() => ProcessStatus)
  status: ProcessStatus;

  @Type(() => Employee)
  validator: Employee;

  @Type(() => ClosingReason)
  closingReason: ClosingReason

  @Type(() => AcquisitionType)
  acquisitionType: AcquisitionType

  @Type(() => StockViewDetails)
  stockDetails: StockViewDetails

  agreedAmount: number;
  paymentOrder: PaymentOrder
  dealSale: Deal
  auto: Auto
  payments: Payment[]
  validations: FileValidation[]

  get amount () {
    return fixPrice(this.agreedAmount)
  }

  get price () {
    const {
      agreedAmount,
      authorizePriceWithOutFormat,
    } = this

    let color = null
    let tooltip = null
    let icon = null

    if (authorizePriceWithOutFormat.value > agreedAmount) {
      color = IndicatorColor.green
      icon = 'mdi-currency-usd'
      tooltip = 'Debajo de precio autorizado'
    }

    return { value: agreedAmount, indicator: { background: color, icon, color: undefined, tooltip } }
  }

  get authorizePrice () {
    const { negotiation, acquisitionType } = this

    if (acquisitionType?.name === 'consignment') {
      return fixPrice(negotiation?.negotiationResponseConsignment?.value)
    }

    return fixPrice(negotiation?.negotiationResponse?.value)
  }

  get authorizePriceWithOutFormat () {
    const { negotiation, acquisitionType } = this

    if (acquisitionType?.name === 'consignment') {
      return negotiation?.negotiationResponseConsignment
    }

    return negotiation?.negotiationResponse
  }

  get marginPositive () {
    const { negotiation, agreedAmount, acquisitionType } = this

    if (acquisitionType?.name === 'consignment') {
      return fixPrice(negotiation?.negotiationResponseConsignment?.value - agreedAmount)
    }

    return fixPrice(negotiation?.negotiationResponse?.value - agreedAmount)
  }

  get client () {
    const { negotiation } = this

    return negotiation?.client
  }

  get appraisalAuto () {
    const { negotiation } = this

    return negotiation?.inspection?.auto
  }
}
