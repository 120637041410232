import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
  {
    path: '/system',
    name: 'system',
    component: () => import('@/layouts/config/Index.vue'),
    meta: { requiresLogin: true, auth: { param: 'role' } },
    children: [
      {
        path: ':role/message',
        name: 'system_message',
        meta: { inherit: true },
        component: () => import('./SystemMessage.vue'),
      },
      {
        path: ':role/web_config',
        name: 'system_web_page',
        meta: { inherit: true },
        component: () => import('./SystemWebPage.vue'),
      },
      {
        path: ':role/config',
        name: 'system_config',
        meta: { inherit: true },
        component: () => import('./SystemConfig.vue'),
      },
    ],
  },
]

export default routes
