import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@mdi/font/css/materialdesignicons.css'
import icons from '@/components/icons'

Vue.use(Vuetify)
export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: '#ff4949',
        secondary: '#ff4949',
        alert: '#ff4949',
        text: '#ff4949',
        background: '#ff4949',
        info: '#ff4949',
        accent: '#ff4949',
        success: '#ff4949',
        warning: '#ff4949',
      },
      light: {
        primary: {
          base: '#6185DB',
          lighten1: '#F9FBFF',
          lighten2: '#EAEFFA',
          lighten3: '#A2B4DF',
          lighten4: '#8AA5E8',
          darken1: '#4964A4',
          darken2: '#30426E',
          darken3: '#101038',
          darken4: '#585874',
        },
        purple: {
          base: '#7D67BC',
          lighten1: '#FCFBFF',
          lighten2: '#E4E3FF',
          lighten3: '#BEAEED',
          darken1: '#5E4D8D',
          darken2: '#3F345E',
        },
        green: {
          base: '#13CE66',
          lighten1: '#E1FCEF',
          lighten2: '#7BD9A5',
          darken1: '#0FA552',
          darken2: '#0A6733',
        },
        blue: {
          base: '#6185DB',
          lighten1: '#E1FCEF',
          lighten2: '#EAEFFA',
          darken3: '#101038',
        },
        red: {
          base: '#F03738',
          lighten1: '#FCE1E1',
          lighten2: '#F35F5F',
        },
        gray: {
          base: '#737373',
        },
        secondary: '#101038',
        lilaBasic: '#7D67BC',
        blackShadow: '#585874',
        greyShadow: '#BABABA',
        alert: '#ff4949',
        text: '#FFFFFF',
        background: '#F5F5F5',
        info: '#1fb6ff',
        accent: '#6185DB',
        success: '#6185DB',
        warning: '#FF9E2C',
        placeHolder: '#AFBEE4',
        iconButtonUnused: '#6185DB',
        iconButtonHover: '#4964A4',
        iconButtonPressed: '#30426E',
        chipsColor: '#eaeffa',
        wingsColor: '#fcfbff',
      },
    },
    options: {
      customProperties: true,
    },
  },
  icons: {
    iconfont: 'mdi',
    values: icons,
  },
})
