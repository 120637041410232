import gql from 'graphql-tag'
import { ExpenseTypeFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${ExpenseTypeFragment}
  query findExpenseType($filter: settings_expense_type_bool_exp!,$order: [settings_expense_type_order_by!], $limit: Int){
    records:settings_expense_type(where: $filter,order_by: $order, limit: $limit) {
      ...ExpenseType
    }
  }
`

export default query
