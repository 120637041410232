import gql from 'graphql-tag'
import { StockAdvertiser } from '@/entities/sales'
import { QueryBuilder } from '../utils'
import { BaseStockFragment, KeyIdentifierFragment, PriceFragment, StockViewDetailBase } from '@/graphql/fragments/sales'
import {
  BaseAttributeFragment,
  BaseComponentFragment,
  BaseComponentValueFragment,
  BaseVersionYearFragment,
  VersionFragment,
  YearFragment,
} from '@/graphql/fragments/vehicle'
import { AcquisitionTypeFragment, ProcessStatusFragment } from '@/graphql/fragments/settings'
import { PipelineFragment } from '@/graphql/fragments/crm'
import { AutoFragment } from '@/graphql/fragments/public'

const query = gql`
  ${BaseStockFragment}
  ${VersionFragment}
  ${BaseVersionYearFragment}
  ${BaseAttributeFragment}
  ${BaseComponentFragment}
  ${BaseComponentValueFragment}
  ${YearFragment}
  ${PriceFragment}
  ${ProcessStatusFragment}
  ${KeyIdentifierFragment}
  ${PipelineFragment}
  ${StockViewDetailBase}
  ${AutoFragment}
  ${AcquisitionTypeFragment}
  query stock($filter: sales_stock_bool_exp, $order: [sales_stock_order_by!], $limit: Int, $offset: Int) {
    records: sales_stock(order_by: $order, limit: $limit, offset: $offset, where: $filter) {
      ...StockBase
      deals {
        id
        createdAt: created_at
        closingReason: closing_reason {
          id
        }
      }
      dailies: stock_dailies(order_by: {id: desc}) {
        id
        status: process_status {
          ...ProcessStatus
        }
        reportDate: report_date
      }
      viewDetails: stock_detail {
        ...StockViewDetailBase
        acquisitionType: acquisition_type{
          ...AcquisitionType
        }
        auto{
          ...Auto
          car {
            id
            ppu
            details(order_by: {created_at: desc}, limit: 1) {
              id
              idStock: id_stock
            }
            posts(where: {status: {_eq: "PUBLIC"}}) {
              id
              url
              platform {
                id
                name
              }
              status
            }
          }
          generation {
            id
            registration:automotive_registrations{
              id
              permission
            }
          }
        }
        attributes(where:{component:{slug:{_in:["mileage","owners_number"]}}})  {
          component {
            id
            slug
            name
            favorite
          }
          componentValue: component_value {
            id
            value
          }
          value
        }
      }
      prices(order_by: {id: desc}, limit: 2) {
        ...Price
      }
      status {
        ...ProcessStatus
      }
      keyIdentifier: key_identifier{
        ...KeyIdentifier
      }
    }
    total: sales_stock_aggregate(where: $filter) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`

export const stockAdvertiser = QueryBuilder(query, StockAdvertiser)
export default stockAdvertiser
