
  import UpdatePwa from '@/components/updatePwa.vue'
  import GlobalNotification from '@/components/global/GlobalNotification.vue'
  import { Component, Watch } from 'vue-property-decorator'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import NetworkDetector from '@/components/NetworkDetector.vue'
  import { Schedule } from '@/entities/persons'
  import { Debounce } from '@/utils/decorators'
  import { UserData } from '@/store/user/state'
  import { Query } from '@/entities/public/Resource/interfaces'
  import { formFilter, insertInput, updateInput } from '@/graphql/generated-types'
  import { File as FileLoad } from '@/entities/files'
  import { Role } from '@/store/user/models'
  import tokenUser from '@/components/tokenUser.vue'

@Component({
  components: { UpdatePwa, GlobalNotification, NetworkDetector },
  methods: {
    ...mapActions('app', ['setMobile', 'setIsResize']),
    ...mapMutations('app', ['setSystem', 'setSchedule']),
    ...mapActions('resources/form', ['fetchData', 'pushData', 'uploadImage', 'deleteFile']),
    ...mapActions('user', ['sendHeartbeat']),
    ...mapActions('notification', ['subscribeToNotifications']),
  },
  computed: {
    ...mapGetters('user', ['user', 'roles']),
  },
})
  export default class App extends tokenUser {
  setMobile!: (payload: boolean) => void
  setIsResize!: (payload: number) => void
  setSystem!: (payload: string) => void
  setSchedule!: (payload: Array<Schedule>) => void
  uploadImage!: (payload: { file: File[], idFileType: number, idProcess: number }) => Promise<Array<FileLoad>>
  sendHeartbeat!: ({ id, metadata }: { id: any, metadata: any }) => Promise<void>
  subscribeToNotifications!: (payload) => void

  user!: UserData
  roles!: Array<Role>
  idProcess: number | null = null
  fieldFile = null
  heartbeatInterval: number | null = null

  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  pushData!: (payload: {
    model: string
    fields?: insertInput | updateInput
  }) => Promise<any>;

  deleteFile!: (
    payload: Array<{ id: number, idFileProcess: number }>
  ) => Promise<void>;

  async mounted () {
    await this.getSystem()
  }

  @Debounce()
  async getSystem () {
    if (localStorage.getItem('apollo-token')) {
      const system = (await this.fetchData({
        query: { name: 'find', model: 'Person' },
        filter: { type: { name: { _eq: 'system' } } },
      }))[0]

      this.setSystem(system?.alias)
    }
  }

  @Watch('$vuetify.breakpoint', { immediate: true, deep: true })
  onBreakpointChange (val) {
    this.setMobile(val.width < 875)
  }

  @Watch('$vuetify.breakpoint.width', { immediate: true, deep: true })
  onWidthChange (val) {
    this.setIsResize(val)
  }

  @Watch('$vuetify.breakpoint.height', { immediate: true, deep: true })
  onHeightChange (val) {
    this.setIsResize(val)
  }

  get changeUser () {
    const { user, fieldFile, idProcess } = this

    return { user, fieldFile, idProcess }
  }

  @Watch('changeUser', { immediate: true, deep: true })
  async onUserChangeApp (val) {
    if (!val?.user?.id) return

    this.subscribeToNotifications({ userId: val?.user?.id, roles: this.roles.map(r => r.slug) })

    if (this.heartbeatInterval) {
      window.clearInterval(this.heartbeatInterval)
      this.heartbeatInterval = null
    }

    this.heartbeatInterval = window.setInterval(async () => {
      const path = this.$router.currentRoute.path
      await this.sendHeartbeat({ id: val.user.id, metadata: { path } })
    }, 30000)
  }

  beforeDestroy () {
    if (this.heartbeatInterval) {
      window.clearInterval(this.heartbeatInterval)
    }
  }
  }
