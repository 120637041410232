import gql from 'graphql-tag'

export const findPpu = gql`
  query findPpu($ppu: String!) {
    auto:find_version_year_by_ppu(ppu: $ppu) {
      year
      versions {
        id
        name
      }
      models{
        id
        name
      }
      brand {
        id
        name
      }
    }
  }
`

export const getActivePurchaseDealByRegistrationPlate = gql`
  query getActivePurchaseDealByRegistrationPlate($registrationPlate: String!) {
    activePurchase:get_active_purchase_deal_by_registration_plate(registrationPlate: $registrationPlate)
  }
`

export const businessRepublishById = gql`
  mutation businessRepublishById($id_stock: Float!, $id_platform: Float!) {
    business_republish_by_platform(id_stock: $id_stock, id_platform: $id_platform)
  }
`
